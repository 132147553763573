@import "styles/_Common.scss";
.Postdetail-wrap {
  width: 100%;
  margin: 0 auto;
  position: relative;

  & > .post-top-nav {
    position: relative;
    border-bottom: 1px solid #e0e0e0;
    height: 56px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    padding-top: 18px;
    & > img {
      margin-right: 11px;
      vertical-align: middle;
    }
    & > span {
      vertical-align: top;
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
    }
  }
  & > div:last-child {
    position: relative;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    & > .post-wrap {
      width: 737px;
      padding-top: 32px;
      & > .post-content {
        max-width: 100%;
        width: 100%;

        & > .main-image {
          max-width: 100%;
          width: 100%;
          padding-bottom: 42px;
          & > img {
            width: 100%;
          }
        }
        & > .main-content {
          img {
            display: block;
            margin : 0 auto;
            max-width: 100%;
            max-height: 100%;
            text-align: center;
          }

          & > h2 {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            margin-bottom: 40px;
          }
          & > p {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
          }
          & > div:last-child {
            position: relative;
            width: 100%;
            max-width: 1120px;
            margin: 0 auto;
            & > .post-wrap {
              width: 737px;
              padding-top: 32px;
              & > .post-content {
                max-width: 100%;
                width: 100%;

                & > .main-image {
                  max-width: 100%;
                  width: 100%;
                  padding-bottom: 42px;
                  & > img {
                    width: 100%;
                    display: block;
                    margin: 0 auto;
                  }
                }
              }
              & > img {
                width: 100%;
                height: auto;
              }
            }
          }
        }

        & > .post-comment {
          margin: 0 0 57px 0;
          & > .comment_count {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            padding-bottom: 18px;
          }
          & > .add-comment {
            max-width: 100%;
            width: 100%;
            margin-bottom: 8px;
            overflow: hidden;
            & > input {
              width: calc(100% - 80px);
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              padding-left: 16px;
              display: block;
              border: 1px solid #e0e0e0;
              border-right: none;
              border-radius: 3px 0 3px 0;
              height: 56px;
              vertical-align: middle;
              float: left;
            }
            & > button {
              display: block;
              height: 56px;
              width: 80px;
              border: 1px solid #e0e0e0;
              border-radius: 0px 3px 3px 3px;
              background-color: #ffffff;
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              vertical-align: middle;
              float: left;
            }
          }

          & > .comments {
            margin-top: 16px;
            padding: 0 16px;
            padding-bottom: 17px;
            border-bottom: 1px solid #e0e0e0;
            & > p {
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 24px;
              margin-bottom: 15px;
              color: rgba(0, 0, 0, 0.65);
            }
            & > .commentModiInput {
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 24px;
              margin-bottom: 15px;
              color: rgba(0, 0, 0, 0.65);
            }
            & > button {
              display: inline-block;
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              height: 24px;
              margin-right: 16px;
              color: rgba(0, 0, 0, 0.65);
            }
            & > .comment-data {
              display: inline-block;
              float: right;

              & > .nickname {
                font-family: IBM Plex Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                vertical-align: middle;
              }
              & > .add-date {
                font-family: IBM Plex Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.3);
                vertical-align: middle;
              }
              & > .nickname::after {
                content: "";
                margin-left: 9px;
                margin-right: 9px;
                border: 1px solid #e0e0e0;
              }
            }
            & > .re-comment {
              margin-top: 33px;
              & > div {
                display: inline-block;
                vertical-align: text-top;
                margin-right: 16px;
              }
              & > .re-comment-text {
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 15px;
                color: rgba(0, 0, 0, 0.65);
                & > button {
                  display: inline-block;
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  height: 24px;
                  margin-right: 16px;
                  color: rgba(0, 0, 0, 0.65);
                }

                & > p {
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  color: rgba(0, 0, 0, 0.65);
                }
              }

              & > button:nth-child(3) {
                margin-left: 34px;
              }

              & > button {
                display: inline-block;
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                height: 24px;
                margin-right: 16px;
                color: rgba(0, 0, 0, 0.65);
              }

              & > .comment-data {
                display: inline-block;
                float: right;
                margin-right: -10px;

                & > .nickname {
                  font-family: IBM Plex Sans;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 12px;
                  vertical-align: middle;
                }
                & > .add-date {
                  font-family: IBM Plex Sans;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  color: rgba(0, 0, 0, 0.3);
                  vertical-align: middle;
                }
                & > .nickname::after {
                  content: "";
                  margin-left: 9px;
                  margin-right: 9px;
                  border: 1px solid #e0e0e0;
                }
              }

              & > .re-comment {
                & > .add-re-comment {
                  width: calc(100% - 34px);
                  display: inline-block;
                  vertical-align: text-top;
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  color: rgba(0, 0, 0, 0.65);
                  margin-right: 0;
                  & > .add-comment {
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 8px;
                    & > input {
                      width: calc(100% - 80px);
                      font-family: Noto Sans KR;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 13px;
                      padding-left: 16px;
                      display: inline-block;
                      border: 1px solid #e0e0e0;
                      border-right: none;
                      border-radius: 3px 0 3px 0;
                      height: 56px;
                      vertical-align: middle;
                    }
                    & > button {
                      display: inline-block;
                      height: 56px;
                      width: 80px;
                      border: 1px solid #e0e0e0;
                      border-radius: 0px 3px 3px 3px;
                      background-color: #ffffff;
                      font-family: Noto Sans KR;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 14px;
                      vertical-align: middle;
                    }
                  }
                }
              }

              & > .add-re-comment {
                width: calc(100% - 34px);
                display: inline-block;
                vertical-align: text-top;
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                color: rgba(0, 0, 0, 0.65);
                margin-right: 0;
                & > .add-comment {
                  max-width: 100%;
                  width: 100%;
                  margin-bottom: 8px;
                  & > input {
                    width: calc(100% - 80px);
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    padding-left: 16px;
                    display: inline-block;
                    border: 1px solid #e0e0e0;
                    border-right: none;
                    border-radius: 3px 0 3px 0;
                    height: 56px;
                    vertical-align: middle;
                  }
                  & > button {
                    display: inline-block;
                    height: 56px;
                    width: 80px;
                    border: 1px solid #e0e0e0;
                    border-radius: 0px 3px 3px 3px;
                    background-color: #ffffff;
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    vertical-align: middle;
                  }
                }
              }
              & > .modi-re-comment {
                width: calc(100% - 34px);
                display: inline-block;
                vertical-align: text-top;
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                color: rgba(0, 0, 0, 0.65);
                margin-right: 0;
                & > .modi-comment {
                  max-width: 100%;
                  width: 100%;
                  margin-bottom: 8px;
                  & > input {
                    width: calc(100% - 80px);
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    padding-left: 16px;
                    display: inline-block;
                    border: 1px solid #e0e0e0;
                    border-right: none;
                    border-radius: 3px 0 3px 0;
                    height: 56px;
                    vertical-align: middle;
                  }
                  & > button {
                    display: inline-block;
                    height: 56px;
                    width: 80px;
                    border: 1px solid #e0e0e0;
                    border-radius: 0px 3px 3px 3px;
                    background-color: #ffffff;
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    vertical-align: middle;
                  }
                }
              }
            }
          }
        }
        & > .house-story {
          position: relative;
          width: calc(100% + 32px);
          margin-left: -16px;
          height: 100%;
          margin-bottom: 64px;
          & > h1 {
            font-weight: 500;
            font-size: 20px;
            line-height: 29px;
            text-align: center;
            margin-bottom: 24px;
          }
          & > .story-cards-btn {
            position: absolute;
            top: 37%;
            left: 0;
            width: 100%;
            z-index: 11;
            & > .s-cards-prev {
              position: absolute;
              top: 0;
              left: 0;
              display: inline-block;
              width: 40px;
              height: 40px;
              border-radius: 6px;
              background: rgba(255, 255, 255, 0.7);
              padding: 7px 0;
              & > .arrow {
                display: inline-block;
                transform: rotate(-45deg);
                margin: 0 16px;
                & > div:first-child {
                  width: 10px;
                  height: 1px;
                  background: #000;
                }
                & > div:last-child {
                  width: 1px;
                  height: 10px;
                  background: #000;
                }
              }
            }
            & > .s-cards-next {
              position: absolute;
              top: 0;
              right: 0;
              display: inline-block;
              width: 40px;
              height: 40px;
              border-radius: 6px;
              background: rgba(255, 255, 255, 0.7);
              padding: 6px 0;
              & > .arrow {
                display: inline-block;
                transform: rotate(135deg);
                margin: 0 13px;
                & > div:first-child {
                  width: 10px;
                  height: 1px;
                  background: #000;
                }
                & > div:last-child {
                  width: 1px;
                  height: 10px;
                  background: #000;
                }
              }
            }
          }
          & > .story-cards-slider {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            overflow: hidden;
            & > .story-cards-inner {
              width: 166.666%;
              & > .story-card {
                cursor: pointer;
                float: left;
                width: calc(100% / 5);
                padding: 0 16px;
                & > .card-image {
                  position: relative;
                  margin-bottom: 17px;

                  & > .card-play {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 48px;
                    height: 48px;
                  }

                  & > img {
                    width: 100%;
                    height: auto;
                  }
                }
                & > .card-title {
                  height: 23px;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 23px;
                  margin-bottom: 10px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                & > .card-footer {
                  width: 100%;
                  & > span {
                    font-weight: 300;
                    font-size: 12px;
                    vertical-align: middle;
                    line-height: 16px;
                    color: #828282;
                    height: 16px;
                    & > img {
                      margin-right: 2px;
                      margin-bottom: -2px;
                    }
                  }
                  & > span:first-child {
                    margin-right: 90px;
                  }
                  & > span:nth-child(2) {
                    margin-right: 14px;
                  }
                }
              }
            }
            & > .story-cards-inner[data-pos-location="0"] {
              margin-left: calc(-33.333% * 0);

              transition: 0.5s all;
            }
            & > .story-cards-inner[data-pos-location="1"] {
              margin-left: calc(-33.333% * 1);

              transition: 0.5s all;
            }
            & > .story-cards-inner[data-pos-location="2"] {
              margin-left: calc(-33.333% * 2);

              transition: 0.5s all;
            }
            & > .story-cards-inner[data-pos-location="3"] {
              margin-left: calc(-33.333% * 3);

              transition: 0.5s all;
            }
            & > .story-cards-inner[data-pos-location="4"] {
              margin-left: calc(-33.333% * 4);

              transition: 0.5s all;
            }
            & > .story-cards-inner[data-pos-location="5"] {
              margin-left: calc(-33.333% * 5);

              transition: 0.5s all;
            }
          }
        }
      }
      & > .fixed-info {
        width: 344px;
        position: absolute;
        top: 40px;
        margin-left: 777px;

        & > .primary_blue {
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          color: #16bdc6;
        }

        & > h1 {
          font-weight: 500;
          font-size: 22px;
          width: 344px;
          margin: 16px 0;
        }
        & > div {
          margin-bottom: 32px;
          & > .publisher {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            float: left;
            & > .profile {
              width: 22px;
              height: 22px;
              overflow: hidden;
              border: 50%;
              display: inline-block;
              margin-right: 6px;
              vertical-align: middle;
              & > img {
                text-align: center;
              }
            }
          }
          & > .write-date {
            float: right;
            display: block;
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 22px;
            color: #828282;
          }
        }
        & > div::after {
          content: "";
          display: block;
          clear: both;
        }

        & > .like {
          vertical-align: middle;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          border-radius: 2px;
          width: 168px;
          height: 40px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          margin-right: 7px;
          & > img {
            display: inline-block;
            vertical-align: middle;
            width: 16px;
            height: 15px;
            margin-right: 6px;
          }
        }

        & > .scrap {
          vertical-align: middle;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          border-radius: 2px;
          width: 168px;
          height: 40px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          & > img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 6px;
          }
        }

        & > .info-notice {
          font-size: 14px;
        }
        & > form {
          & > select {
            margin-top: 24px;
            width: 100%;
            height: 36px;
            background-color: #ffffff;
            border: 1px solid #e0e0e0;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.3);
            margin-bottom: 25px;
          }
          & > button {
            background: #4f4f4f;
            border-radius: 4px;
            height: 48px;
            width: 100%;
            color: #ffffff;
          }
        }
        & > .util-buttons {
          position: relative;
          margin-bottom: 12px;
          & > div {
            cursor: pointer;
            width: 36px;
            height: 36px;
            border: 1px solid #e0e0e0;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            float: left;
          }

          & > div:nth-child(3):hover ~ .hover-util {
            width: 100%;
            transition: 0.5s all;
            border: 1px solid #e0e0e0;
          }
          & > .hover-util {
            border: none;
            transition: 0.5s all;
            background-color: #ffffff;
            position: absolute;
            max-width: 240px;
            width: 0%;
            overflow: hidden;
            left: 144px;
            & > a {
              margin: 0 11.5px;
            }
          }
          & > .hover-util:hover {
            border: 1px solid #e0e0e0;
            width: 100%;
          }
          & > div:not(last-child) {
            margin-right: 8px;
          }
          & > button {
            float: right;
            width: 104px;
            height: 36px;
            background-color: #fff;
            border: 1px solid #e0e0e0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            border-radius: 2px;
            & > img {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

@include media("<=medium", ">=small") {
  .Postdetail-wrap {
    width: 100%;
    position: relative;

    & > .post-top-nav {
      border-bottom: 1px solid #e0e0e0;
      height: 56px;
      max-width: 100%;
      width: 100%;
      text-align: center;
      vertical-align: middle;
      padding-top: 18px;
      & > img {
        margin-right: 11px;
        vertical-align: middle;
      }
      & > span {
        vertical-align: top;
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
      }
    }
    & > div:last-child {
      & > .post-wrap {
        max-width: 100%;
        width: 100%;
        padding-top: 32px;
        & > .post-content {
          max-width: 100%;
          width: 100%;

          & > .main-image {
            width: 92%;
            margin: 0 auto;
            padding-bottom: 42px;
            overflow: hidden;
            & > img {
              // margin-left: -20px;
              width: 95vw;
              object-fit: cover;
            }
          }
          & > .main-content {
            width: 92%;
            margin: 0 auto;

            & > h2 {
              margin-top: 32px;
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: bold;
              font-size: 24px;
              margin-bottom: 16px;
            }
            & > div {
              & > p {
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 28px;
                color: rgba(0, 0, 0, 0.65);
              }
            }
            & > img {
              margin: 40px 0;
              max-width: 100%;
            }
          }

          & > .post-comment {
            width: 92%;
            margin: 0 auto;
            margin-bottom: 57px;
            padding: 0 10px;
            & > .comment_count {
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              padding-bottom: 18px;
            }
            & > .add-comment {
              max-width: 100%;
              width: 100%;
              margin-bottom: 8px;
              & > input {
                width: calc(100% - 80px);
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                padding-left: 16px;
                display: inline-block;
                border: 1px solid #e0e0e0;
                border-right: none;
                border-radius: 3px 0 3px 0;
                height: 56px;
                vertical-align: middle;
              }
              & > button {
                display: inline-block;
                height: 56px;
                width: 80px;
                border: 1px solid #e0e0e0;
                border-radius: 0px 3px 3px 3px;
                background-color: #ffffff;
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                vertical-align: middle;
              }
            }

            & > .comments {
              margin-top: 16px;
              padding: 0 0px;
              padding-bottom: 17px;
              border-bottom: 1px solid #e0e0e0;
              & > p {
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 15px;
                color: rgba(0, 0, 0, 0.65);
              }
              & > button {
                display: inline-block;
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                height: 24px;
                margin-right: 16px;
                color: rgba(0, 0, 0, 0.65);
              }
              & > .comment-data {
                display: inline-block;
                float: right;

                & > .nickname {
                  font-family: IBM Plex Sans;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 12px;
                  vertical-align: middle;
                }
                & > .add-date {
                  font-family: IBM Plex Sans;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  color: rgba(0, 0, 0, 0.3);
                  vertical-align: middle;
                }
                & > .nickname::after {
                  content: "";
                  margin-left: 9px;
                  margin-right: 9px;
                  border: 1px solid #e0e0e0;
                }
              }
              & > .re-comment {
                margin-top: 33px;
                & > div {
                  display: inline-block;
                  vertical-align: text-top;
                  margin-right: 13px;
                }
                & > .re-comment-text {
                  max-width: 263px;
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 13px;
                  line-height: 21px;
                  margin-bottom: 15px;
                  color: rgba(0, 0, 0, 0.65);
                }

                & > button:nth-child(3) {
                  margin-left: 34px;
                }

                & > button {
                  display: inline-block;
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  height: 24px;
                  margin-right: 16px;
                  color: rgba(0, 0, 0, 0.65);
                }

                & > .comment-data {
                  display: inline-block;
                  float: right;
                  margin-right: -10px;

                  & > .nickname {
                    font-family: IBM Plex Sans;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    vertical-align: middle;
                  }
                  & > .add-date {
                    font-family: IBM Plex Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.3);
                    vertical-align: middle;
                  }
                  & > .nickname::after {
                    content: "";
                    margin-left: 9px;
                    margin-right: 9px;
                    border: 1px solid #e0e0e0;
                  }
                }
                & > .add-re-comment {
                  width: calc(100% - 34px);
                  display: inline-block;
                  vertical-align: text-top;
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  color: rgba(0, 0, 0, 0.65);
                  margin-right: 0;
                  & > .add-comment {
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 8px;
                    & > input {
                      min-width: calc(276px - 42px);
                      font-family: Noto Sans KR;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 12px;
                      padding-left: 16px;
                      display: inline-block;
                      border: 1px solid #e0e0e0;
                      border-right: none;
                      border-radius: 3px 0 3px 0;
                      height: 40px;
                      vertical-align: middle;
                    }
                    & > button {
                      display: inline-block;
                      height: 40px;
                      width: 42px;
                      border: 1px solid #e0e0e0;
                      border-radius: 0px 3px 3px 3px;
                      background-color: #ffffff;
                      font-family: Noto Sans KR;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 14px;
                      vertical-align: middle;
                    }
                  }
                }
                & > .modi-re-comment {
                  width: calc(100% - 34px);
                  display: inline-block;
                  vertical-align: text-top;
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  color: rgba(0, 0, 0, 0.65);
                  margin-right: 0;
                  & > .modi-comment {
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 8px;
                    & > input {
                      min-width: calc(276px - 42px);
                      font-family: Noto Sans KR;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 12px;
                      padding-left: 16px;
                      display: inline-block;
                      border: 1px solid #e0e0e0;
                      border-right: none;
                      border-radius: 3px 0 3px 0;
                      height: 40px;
                      vertical-align: middle;
                    }
                    & > button {
                      display: inline-block;
                      height: 40px;
                      width: 42px;
                      border: 1px solid #e0e0e0;
                      border-radius: 0px 3px 3px 3px;
                      background-color: #ffffff;
                      font-family: Noto Sans KR;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 14px;
                      vertical-align: middle;
                    }
                  }
                }
              }
            }
          }
          & > .house-story {
            width: 100%;
            margin-top: 32px;
            margin-left: 0px;
            & > h1 {
              font-weight: 500;
              font-size: 20px;
              line-height: 29px;
              text-align: left;
              margin-bottom: 16px;
              margin-left: 20px;
            }
            & > .story-cards-btn {
              position: absolute;
              top: 36%;
              left: 0;
              width: 100%;
              z-index: 11;
              & > .s-cards-prev {
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                width: 40px;
                height: 40px;
                border-radius: 6px;
                background: rgba(255, 255, 255, 0.7);
                padding: 7px 0;
                & > .arrow {
                  display: inline-block;
                  transform: rotate(-45deg);
                  margin: 0 16px;
                  & > div:first-child {
                    width: 10px;
                    height: 1px;
                    background: #000;
                  }
                  & > div:last-child {
                    width: 1px;
                    height: 10px;
                    background: #000;
                  }
                }
              }
              & > .s-cards-next {
                position: absolute;
                top: 0;
                right: 0;
                display: inline-block;
                width: 40px;
                height: 40px;
                border-radius: 6px;
                background: rgba(255, 255, 255, 0.7);
                padding: 6px 0;
                & > .arrow {
                  display: inline-block;
                  transform: rotate(135deg);
                  margin: 0 13px;
                  & > div:first-child {
                    width: 10px;
                    height: 1px;
                    background: #000;
                  }
                  & > div:last-child {
                    width: 1px;
                    height: 10px;
                    background: #000;
                  }
                }
              }
            }
            & > .story-cards-slider {
              position: relative;
              display: block;
              width: 100%;
              height: 100%;
              overflow: scroll;
              & > .story-cards-inner {
                width: 200%;
                overflow: hidden;
                margin-left: 20px;
                & > .story-card {
                  float: left;
                  width: calc(100% / 3);
                  padding: 0 16px;
                  & > .card-image {
                    margin-bottom: 17px;

                    & > img {
                      width: 100%;
                      height: auto;
                      object-fit: cover;
                    }
                  }
                  & > .card-title {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 23px;
                    margin-bottom: 10px;
                  }

                  & > .card-footer {
                    width: 100%;
                    & > span {
                      font-weight: 300;
                      font-size: 12px;
                      vertical-align: middle;
                      line-height: 16px;
                      color: #828282;
                      height: 16px;
                      & > img {
                        margin-right: 2px;
                        margin-bottom: -2px;
                      }
                    }
                    & > span:first-child {
                      margin-right: 31%;
                    }
                    & > span:last-child {
                      margin-left: 5%;
                    }
                  }
                }
              }
              & > .story-cards-inner[data-pos-location="0"] {
                margin-left: 20px;

                transition: 0.5s all;
              }
              & > .story-cards-inner[data-pos-location="1"] {
                margin-left: calc(20px + (-60% * 1));

                transition: 0.5s all;
              }
              & > .story-cards-inner[data-pos-location="2"] {
                margin-left: calc(20px + (-60% * 2));

                transition: 0.5s all;
              }
              & > .story-cards-inner[data-pos-location="3"] {
                margin-left: calc(20px + (-60% * 3));

                transition: 0.5s all;
              }
              & > .story-cards-inner[data-pos-location="4"] {
                margin-left: calc(20px + (-60% * 4));

                transition: 0.5s all;
              }
            }
          }
        }
      }
      & > .fixed-info {
        max-width: 384px;
        display: none;
        width: 100%;
        top: 180px;
        right: 27%;
        position: fixed;
        height: 100%;

        & > .primary_blue {
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          color: #16bdc6;
        }

        & > h1 {
          font-weight: 500;
          font-size: 22px;
          padding-bottom: 11px;
          width: 344px;
          margin-top: 10px;
        }

        & > .publisher {
          font-family: IBM Plex Sans;
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          & > .profile {
            width: 22px;
            height: 22px;
            overflow: hidden;
            border: 50%;
            display: inline-block;
            margin-right: 6px;
            vertical-align: middle;
            & > img {
              text-align: center;
            }
          }
        }
        & > .write-date {
          display: block;
          margin-top: 15px;
          padding-bottom: 24px;
          border-bottom: 1px solid #e0e0e0;
          font-family: IBM Plex Sans;
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          margin-bottom: 24px;
        }

        & > .like {
          vertical-align: middle;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          border-radius: 2px;
          width: 168px;
          height: 40px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          margin-right: 7px;
          & > img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 6px;
          }
        }

        & > .scrap {
          vertical-align: middle;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          border-radius: 2px;
          width: 168px;
          height: 40px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          & > img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 6px;
          }
        }

        & > .info-notice {
          font-size: 14px;
        }
        & > form {
          & > select {
            margin-top: 24px;
            width: 100%;
            height: 36px;
            background-color: #ffffff;
            border: 1px solid #e0e0e0;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.3);
            margin-bottom: 25px;
          }
          & > button {
            background: #4f4f4f;
            border-radius: 4px;
            height: 48px;
            width: 100%;
            color: #ffffff;
          }
        }
        & > .util-buttons {
          position: relative;
          margin-top: 24px;
          margin-bottom: 100px;
          & > div {
            cursor: pointer;
            width: 40px;
            height: 40px;
            border: 1px solid #e0e0e0;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            float: left;
          }
          & > div:nth-child(3):hover ~ .hover-util {
            width: 100%;
            transition: 0.5s all;
            border: 1px solid #e0e0e0;
          }
          & > .hover-util {
            border: none;
            transition: 0.5s all;
            background-color: #ffffff;
            position: absolute;
            max-width: 240px;
            width: 0%;
            overflow: hidden;
            left: 144px;
            & > a {
              margin: 0 11.5px;
            }
          }
          & > .hover-util:hover {
            border: 1px solid #e0e0e0;
            width: 100%;
          }
          & > div:not(last-child) {
            margin-right: 8px;
          }
          & > button {
            float: right;
            width: 104px;
            height: 36px;
            background-color: #fff;
            border: 1px solid #e0e0e0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            border-radius: 2px;
            & > img {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.main-content {
  & > h2 {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 40px;
  }
  p {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.65);
    img {
      width: 100%;
      height: auto;
      margin: 40px 0;
    }
    span {
      span {
        img {
          width: 100%;
          height: auto;
        }
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
