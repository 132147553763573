@charset "UTF-8";
* {
  -webkit-tap-highlight-color: transparent; }

input, button {
  -webkit-appearance: none !important; }

.search-wrap {
  overflow: hidden;
  position: relative;
  height: calc(100vh - 90px); }
  .search-wrap > .search-bar {
    width: 100%;
    background-color: #fff;
    height: 56px;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0; }
    .search-wrap > .search-bar > form {
      height: 100%;
      width: 100%; }
      .search-wrap > .search-bar > form > label {
        position: relative;
        float: left; }
        .search-wrap > .search-bar > form > label > input {
          height: 100%;
          width: 100%;
          padding-left: 31px;
          font-size: 13px;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;
          opacity: 0.5; }
      .search-wrap > .search-bar > form > .search {
        height: 100%;
        width: 336px; }
        .search-wrap > .search-bar > form > .search > div {
          width: 16px;
          height: 16px;
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          right: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          vertical-align: middle;
          color: #e0e0e0;
          font-size: 13px; }
        .search-wrap > .search-bar > form > .search > .search-clear {
          display: none;
          right: 57px; }
      .search-wrap > .search-bar > form > .filter-bar {
        height: 100%;
        width: calc(100% - 456px);
        padding-left: 24px;
        line-height: 56px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.3); }
        .search-wrap > .search-bar > form > .filter-bar > .filter-tag {
          position: relative;
          display: inline-block;
          min-width: 77px;
          max-width: 104px;
          width: 100px;
          height: 24px;
          padding: 0;
          margin: 5px;
          color: rgba(0, 0, 0, 0.65);
          text-align: center;
          line-height: 24px;
          border-radius: 4px;
          background: #e0e0e0; }
          .search-wrap > .search-bar > form > .filter-bar > .filter-tag > input {
            width: 100%;
            padding-left: 8px;
            height: 100%;
            font-size: 12px;
            text-align: left;
            line-height: 20px; }
          .search-wrap > .search-bar > form > .filter-bar > .filter-tag > img {
            position: absolute;
            top: 5px;
            right: 6px; }
        .search-wrap > .search-bar > form > .filter-bar > div {
          width: 60px;
          height: 21px;
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          right: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          vertical-align: middle;
          color: #e0e0e0;
          font-size: 13px; }
          .search-wrap > .search-bar > form > .filter-bar > div > img {
            margin-right: 8px;
            width: 16px;
            height: 16px;
            object-fit: contain; }
      .search-wrap > .search-bar > form > .filter-push {
        height: 100%;
        width: 120px;
        border-left: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0; }
        .search-wrap > .search-bar > form > .filter-push > div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          text-align: center;
          vertical-align: middle;
          color: #16bdc6;
          font-size: 13px; }
          .search-wrap > .search-bar > form > .filter-push > div > img {
            margin-right: 8px;
            width: 16px;
            height: 16px;
            object-fit: contain; }
  .search-wrap > .filter-list {
    width: 100%;
    padding: 24px 50px 0 50px;
    box-sizing: border-box;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
    min-height: 260px;
    position: absolute;
    display: flex;
    top: 56px;
    z-index: 100;
    background-color: #fff; }
    .search-wrap > .filter-list > div {
      width: 100%;
      min-width: 1100px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 100%; }
      .search-wrap > .filter-list > div > .filter-list-left {
        float: left;
        width: 40%;
        box-sizing: border-box; }
        .search-wrap > .filter-list > div > .filter-list-left > .vrhouse {
          float: left;
          width: 50%;
          height: 200px;
          box-sizing: border-box;
          padding: 0 40px;
          border-right: 1px solid #e0e0e0; }
          .search-wrap > .filter-list > div > .filter-list-left > .vrhouse > h3 {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            font-size: 16px;
            margin-bottom: 16px; }
          .search-wrap > .filter-list > div > .filter-list-left > .vrhouse > div {
            height: 136px;
            margin-top: 8px;
            border: 1px solid #e2e2e2;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center; }
            .search-wrap > .filter-list > div > .filter-list-left > .vrhouse > div > img {
              width: 80px;
              height: 80px; }
        .search-wrap > .filter-list > div > .filter-list-left > .trade-type {
          float: left;
          width: 50%;
          height: 200px;
          box-sizing: border-box;
          padding: 0 40px;
          border-right: 1px solid #e0e0e0; }
          .search-wrap > .filter-list > div > .filter-list-left > .trade-type > h3 {
            margin-bottom: 16px;
            font-size: 16px;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 16px; }
          .search-wrap > .filter-list > div > .filter-list-left > .trade-type > div > label {
            display: block;
            margin: 8px 0; }
            .search-wrap > .filter-list > div > .filter-list-left > .trade-type > div > label > div {
              text-align: center;
              line-height: 40px;
              font-size: 13px;
              display: block;
              width: 100%;
              height: 40px;
              border-radius: 3px;
              border: 1px solid #e0e0e0;
              background-color: #fff;
              transition: 0.5s all; }
            .search-wrap > .filter-list > div > .filter-list-left > .trade-type > div > label > input[type="checkbox"] {
              display: none; }
            .search-wrap > .filter-list > div > .filter-list-left > .trade-type > div > label > input:checked ~ div {
              transition: 0.5s all;
              color: #fff;
              background-color: #16bdc6;
              border-color: #16bdc6; }
          .search-wrap > .filter-list > div > .filter-list-left > .trade-type > div > label:last-child {
            margin-bottom: 12px; }
      .search-wrap > .filter-list > div > .filter-list-right {
        width: 60%;
        box-sizing: border-box; }
        .search-wrap > .filter-list > div > .filter-list-right > .deposit {
          float: left;
          width: 40%;
          box-sizing: border-box;
          padding: 0 40px;
          height: 200px; }
          .search-wrap > .filter-list > div > .filter-list-right > .deposit > h3 {
            position: relative;
            margin-bottom: 16px;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 16px; }
          .search-wrap > .filter-list > div > .filter-list-right > .deposit > h3:nth-child(1) > span {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            color: #16bdc6;
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px; }
          .search-wrap > .filter-list > div > .filter-list-right > .deposit > h3:nth-child(4) {
            margin-top: 40px; }
          .search-wrap > .filter-list > div > .filter-list-right > .deposit > h3:nth-child(4) > span {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            color: #16bdc6;
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px; }
          .search-wrap > .filter-list > div > .filter-list-right > .deposit > .range-helper {
            width: 100%;
            margin-top: 8px;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.3); }
            .search-wrap > .filter-list > div > .filter-list-right > .deposit > .range-helper > span {
              display: inline-block;
              line-height: 0.8; }
            .search-wrap > .filter-list > div > .filter-list-right > .deposit > .range-helper > span:nth-child(1) {
              text-align: left;
              width: 28%; }
            .search-wrap > .filter-list > div > .filter-list-right > .deposit > .range-helper > span:nth-child(2) {
              text-align: center;
              width: 43%;
              border-left: 1px solid #dbdbdb;
              border-right: 1px solid #dbdbdb; }
            .search-wrap > .filter-list > div > .filter-list-right > .deposit > .range-helper > span:nth-child(3) {
              text-align: right;
              width: 28%; }
        .search-wrap > .filter-list > div > .filter-list-right > .residency {
          float: left;
          width: 60%;
          height: 200px;
          box-sizing: border-box;
          padding: 0 40px;
          border-left: 1px solid #e0e0e0; }
          .search-wrap > .filter-list > div > .filter-list-right > .residency > h3 {
            margin-bottom: 16px;
            font-size: 16px;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 16px; }
          .search-wrap > .filter-list > div > .filter-list-right > .residency > div {
            height: 40px; }
            .search-wrap > .filter-list > div > .filter-list-right > .residency > div > label {
              width: calc(100% / 4);
              display: inline-block; }
              .search-wrap > .filter-list > div > .filter-list-right > .residency > div > label > div {
                text-align: center;
                line-height: 40px;
                font-size: 13px;
                display: block;
                max-width: 100%;
                height: 40px;
                border: 1px solid #e0e0e0;
                background-color: #fff;
                transition: 0.5s all; }
              .search-wrap > .filter-list > div > .filter-list-right > .residency > div > label > input[type="checkbox"] {
                display: none; }
              .search-wrap > .filter-list > div > .filter-list-right > .residency > div > label > input:checked ~ div {
                transition: 0.5s all;
                color: #fff;
                background-color: #16bdc6;
                border-color: #16bdc6;
                border-radius: 3px 0px 0px 3px; }
          .search-wrap > .filter-list > div > .filter-list-right > .residency > div:nth-child(2) {
            margin-bottom: 40px; }
  .search-wrap > .search-help {
    z-index: 999;
    background-color: #fff;
    width: 336px;
    height: 100vh;
    position: absolute;
    display: table;
    top: 55px;
    left: -336px;
    border-right: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    padding-top: 8px; }
    .search-wrap > .search-help > .helpClose {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 9999;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px; }
    .search-wrap > .search-help > .help-content {
      margin: 4px 0;
      padding-left: 32px;
      width: 100%;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      cursor: pointer; }
      .search-wrap > .search-help > .help-content > img {
        margin-right: 15px;
        float: left;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        object-fit: contain; }
      .search-wrap > .search-help > .help-content > p {
        float: left;
        vertical-align: middle;
        font-size: 13px;
        margin-bottom: 0; }
  .search-wrap > .search-help[data-help-toggle="true"] {
    left: 0; }
  .search-wrap > .search-result {
    width: 100%;
    height: calc(100vh - 127px);
    overflow: hidden; }
    .search-wrap > .search-result > .search-result-left {
      float: left;
      width: calc(100% - 448px);
      height: 100%; }
    .search-wrap > .search-result > .search-result-right {
      float: right;
      width: 448px;
      background-color: #fff;
      border-left: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      height: 100%; }
      .search-wrap > .search-result > .search-result-right > .arrow-btn {
        display: none; }
      .search-wrap > .search-result > .search-result-right > .result-head {
        height: 57px;
        width: 100%;
        background-color: #fff; }
        .search-wrap > .search-result > .search-result-right > .result-head > p {
          float: left;
          margin-top: 21px;
          margin-left: 31px;
          font-size: 14px;
          color: #323232; }
          .search-wrap > .search-result > .search-result-right > .result-head > p > span {
            font-weight: bold; }
        .search-wrap > .search-result > .search-result-right > .result-head > button {
          margin-top: 19px;
          margin-right: 32px;
          float: right;
          width: 104px;
          height: 36px;
          background-color: #fff;
          border: 1px solid #e0e0e0;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          border-radius: 2px; }
          .search-wrap > .search-result > .search-result-right > .result-head > button > img {
            margin-right: 10px;
            width: 28px;
            height: 28px;
            object-fit: contain; }
        .search-wrap > .search-result > .search-result-right > .result-head > div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          text-align: center;
          vertical-align: middle;
          color: #16bdc6;
          font-size: 13px; }
          .search-wrap > .search-result > .search-result-right > .result-head > div > img {
            margin-right: 8px; }
      .search-wrap > .search-result > .search-result-right > .result-body {
        width: 100%;
        height: calc(100% - 57px);
        overflow-x: hidden;
        overflow-y: auto; }
        .search-wrap > .search-result > .search-result-right > .result-body::-webkit-scrollbar {
          width: 8px;
          background: rgba(0, 0, 0, 0); }
        .search-wrap > .search-result > .search-result-right > .result-body::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.4); }
        .search-wrap > .search-result > .search-result-right > .result-body::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0); }
        .search-wrap > .search-result > .search-result-right > .result-body > a {
          display: block;
          width: 100%;
          height: 140px;
          padding: 10px; }
          .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item {
            width: 100%;
            height: 140px;
            padding: 10px; }
            .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item:hover {
              background: #f2f2f2; }
            .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-img {
              float: left;
              position: relative;
              width: 160px;
              height: 120px;
              border-radius: 2px;
              overflow: hidden;
              margin-right: 13px;
              cursor: pointer; }
              .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-img > img {
                width: 100%;
                height: 100%; }
              .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-img > .star {
                width: 20px;
                height: 20px;
                position: absolute;
                right: 8px;
                top: 8px;
                cursor: pointer; }
              .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-img > .vr {
                width: 25px;
                height: 25px;
                position: absolute;
                right: 39px;
                top: 7px;
                cursor: pointer; }
              .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-img > .add-compare {
                position: absolute;
                bottom: -36px;
                width: 100%;
                height: 36px;
                border-radius: 2px;
                background-color: rgba(0, 0, 0, 0.3);
                color: #fff;
                transition: 0.5s all;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                border-radius: 2px; }
                .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-img > .add-compare > img {
                  width: 24px;
                  height: 24px;
                  object-fit: contain;
                  margin-right: 10px; }
            .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-img:hover > .add-compare {
              bottom: 0;
              transition: 0.5s all; }
            .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content {
              float: left;
              position: relative;
              width: 209px;
              height: 120px;
              border-radius: 2px;
              overflow: hidden; }
              .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content > .types > span {
                font-size: 11px;
                color: rgba(0, 0, 0, 0.3); }
              .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content > .item-title {
                font-size: 16px;
                height: 24px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-weight: 500;
                color: #323232;
                display: block; }
              .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content > .item-location {
                font-size: 12px;
                font-weight: 300;
                color: #828282;
                height: 14px; }
              .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content > .item-price {
                font-size: 12px;
                font-weight: 300;
                line-height: 21px;
                color: #828282; }
                .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content > .item-price > p {
                  font-size: 12px;
                  font-weight: 300;
                  line-height: 21px;
                  color: #323232;
                  margin-bottom: 0; }
                  .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content > .item-price > p > span {
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 21px; }
                  .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content > .item-price > p > span:first-child {
                    width: 58px;
                    display: inline-block;
                    color: #828282; }
                  .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content > .item-price > p > span:last-child {
                    margin-left: 12px;
                    color: #323232; }
  .search-wrap .primary-blue-btn {
    width: 58px;
    height: 20px;
    background: #16bdc6;
    border-radius: 2px;
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    line-height: 11px;
    margin-right: 8px; }
  .search-wrap .nomination-btn {
    width: 30px;
    height: 20px;
    background: #fc9852;
    opacity: 0.8;
    border-radius: 2px;
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    line-height: 11px;
    margin-right: 5px; }
  .search-wrap .search-result-right::-webkit-scrollbar {
    display: none; }
  .search-wrap .filter-list[data-filter_toggle="true"] {
    display: flex; }
  .search-wrap .filter-list[data-filter_toggle="false"] {
    display: none; }

@media (max-width: 1080px) and (min-width: 280px) {
  .search-wrap {
    overflow-x: hidden;
    position: relative;
    height: auto; }
    .search-wrap > .search-bar {
      width: 100%;
      background-color: #fff;
      height: 56px;
      border-bottom: 1px solid #e0e0e0; }
      .search-wrap > .search-bar > form {
        height: 100%;
        width: 100%; }
        .search-wrap > .search-bar > form > label {
          position: relative;
          float: left; }
          .search-wrap > .search-bar > form > label > input {
            height: 100%;
            width: 100%;
            padding-left: 16px;
            font-size: 13px;
            border-left: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0; }
        .search-wrap > .search-bar > form > .search {
          display: block;
          height: 100%;
          width: 80%; }
          .search-wrap > .search-bar > form > .search > div {
            width: 16px;
            height: 16px;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            right: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            vertical-align: middle;
            color: #e0e0e0;
            font-size: 13px; }
          .search-wrap > .search-bar > form > .search > .search-clear {
            display: none;
            right: 57px; }
        .search-wrap > .search-bar > form > .filter-bar {
          height: 100%;
          display: none;
          width: 80%;
          padding-left: 24px;
          line-height: 56px; }
          .search-wrap > .search-bar > form > .filter-bar > .filter-tag {
            position: relative;
            display: none;
            height: 24px;
            min-width: 77px;
            width: 84px;
            max-width: 104px;
            background: #e0e0e0;
            border-radius: 4px;
            text-align: center;
            padding: 0;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.65); }
            .search-wrap > .search-bar > form > .filter-bar > .filter-tag > input {
              width: 100%;
              padding-left: 8px;
              height: 100%;
              font-size: 12px;
              text-align: left;
              line-height: 20px; }
            .search-wrap > .search-bar > form > .filter-bar > .filter-tag > img {
              position: absolute;
              top: 5px;
              right: 6px; }
          .search-wrap > .search-bar > form > .filter-bar > div {
            width: 60px;
            height: 21px;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            right: 14px;
            display: none;
            justify-content: center;
            align-items: center;
            text-align: center;
            vertical-align: middle;
            color: #e0e0e0;
            font-size: 13px; }
            .search-wrap > .search-bar > form > .filter-bar > div > img {
              margin-right: 8px; }
        .search-wrap > .search-bar > form > .filter-bar::after {
          content: "지역/지하철/대학교/하우스 명";
          font-size: 14px;
          color: rgba(0, 0, 0, 0.3); }
        .search-wrap > .search-bar > form > .filter-push {
          height: 100%;
          width: 20%;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0; }
          .search-wrap > .search-bar > form > .filter-push > div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            text-align: center;
            vertical-align: middle;
            color: #16bdc6;
            font-size: 13px; }
            .search-wrap > .search-bar > form > .filter-push > div > span {
              display: none; }
            .search-wrap > .search-bar > form > .filter-push > div > img {
              margin-right: 8px; }
    .search-wrap > .filter-list {
      width: 100%;
      height: 100%;
      box-shadow: 0px 20px 20px rgba(0, 0, 0, 0);
      position: absolute;
      display: flex;
      justify-content: center;
      z-index: 998;
      background-color: #fff;
      padding: 0;
      overflow-y: auto; }
      .search-wrap > .filter-list::-webkit-scrollbar {
        width: 0px;
        opacity: 0; }
      .search-wrap > .filter-list > div {
        display: block;
        position: relative;
        width: 100%;
        height: 780px;
        min-width: 100%;
        max-width: 430px;
        padding: 0 16px;
        margin: 0 auto; }
        .search-wrap > .filter-list > div > div {
          width: 100%;
          float: none;
          height: auto;
          margin: 16px 0;
          margin-bottom: 0; }
        .search-wrap > .filter-list > div > div:first-child {
          display: inline-block;
          width: 100%;
          padding: 0;
          height: auto; }
          .search-wrap > .filter-list > div > div:first-child > div {
            width: 100%;
            display: inline-block;
            width: calc(100% / 2);
            padding: 0 40px;
            vertical-align: text-top;
            max-height: 192px;
            height: 100%; }
          .search-wrap > .filter-list > div > div:first-child > div:first-child {
            position: absolute;
            width: 100%;
            border-right: 0px;
            padding: 0 16px;
            bottom: 0;
            left: 0; }
            .search-wrap > .filter-list > div > div:first-child > div:first-child > h3 {
              display: none; }
            .search-wrap > .filter-list > div > div:first-child > div:first-child > div {
              height: 60px;
              margin-top: 8px; }
              .search-wrap > .filter-list > div > div:first-child > div:first-child > div > img {
                width: 36px;
                height: 36px; }
          .search-wrap > .filter-list > div > div:first-child > .trade-type {
            width: 100%;
            border-right: 0px;
            padding: 0;
            height: auto; }
            .search-wrap > .filter-list > div > div:first-child > .trade-type > h3 {
              margin-top: 8px;
              margin-bottom: 16px;
              font-size: 16px; }
            .search-wrap > .filter-list > div > div:first-child > .trade-type > div {
              width: 100%;
              display: flex;
              align-content: center; }
              .search-wrap > .filter-list > div > div:first-child > .trade-type > div > label:nth-child(2) {
                margin: 0 8px; }
              .search-wrap > .filter-list > div > div:first-child > .trade-type > div > label {
                width: 100%;
                display: block;
                margin: 0px;
                height: 40px; }
                .search-wrap > .filter-list > div > div:first-child > .trade-type > div > label > div {
                  width: 100%;
                  text-align: center;
                  line-height: 40px;
                  font-size: 13px;
                  display: block;
                  height: 40px;
                  border-radius: 3px;
                  border: 1px solid #e0e0e0;
                  background-color: #fff;
                  transition: 0.5s all; }
                .search-wrap > .filter-list > div > div:first-child > .trade-type > div > label > input[type="checkbox"] {
                  display: none; }
                .search-wrap > .filter-list > div > div:first-child > .trade-type > div > label > input:checked ~ div {
                  transition: 0.5s all;
                  color: #fff;
                  background-color: #16bdc6;
                  border-color: #16bdc6; }
              .search-wrap > .filter-list > div > div:first-child > .trade-type > div > label:last-child {
                margin-bottom: 12px; }
        .search-wrap > .filter-list > div > div:last-child {
          display: inline-block;
          width: 100%;
          height: auto; }
          .search-wrap > .filter-list > div > div:last-child > div {
            width: 100%;
            padding: 0;
            display: inline-block;
            vertical-align: text-top; }
          .search-wrap > .filter-list > div > div:last-child > .deposit {
            float: none;
            width: 100%;
            padding: 0;
            height: auto; }
            .search-wrap > .filter-list > div > div:last-child > .deposit > h3 {
              margin-top: 8px;
              margin-bottom: 16px;
              height: 16px; }
            .search-wrap > .filter-list > div > div:last-child > .deposit > div {
              padding: 8px; }
            .search-wrap > .filter-list > div > div:last-child > .deposit > h3:nth-child(4) {
              margin-top: 24px; }
          .search-wrap > .filter-list > div > div:last-child > .residency {
            float: none;
            width: 100%;
            height: auto;
            padding: 0;
            border-left: 0px; }
            .search-wrap > .filter-list > div > div:last-child > .residency > h3 {
              margin-bottom: 16px;
              font-size: 16px;
              height: 20px; }
            .search-wrap > .filter-list > div > div:last-child > .residency > div {
              height: 40px; }
              .search-wrap > .filter-list > div > div:last-child > .residency > div > label {
                width: calc(100% / 4);
                display: inline-block; }
                .search-wrap > .filter-list > div > div:last-child > .residency > div > label > div {
                  text-align: center;
                  line-height: 40px;
                  font-size: 13px;
                  display: block;
                  max-width: 100%;
                  height: 40px;
                  border: 1px solid #e0e0e0;
                  background-color: #fff;
                  transition: 0.5s all; }
                .search-wrap > .filter-list > div > div:last-child > .residency > div > label > input[type="checkbox"] {
                  display: none; }
                .search-wrap > .filter-list > div > div:last-child > .residency > div > label > input:checked ~ div {
                  transition: 0.5s all;
                  color: #fff;
                  background-color: #16bdc6;
                  border-color: #16bdc6;
                  border-radius: 3px 0px 0px 3px; }
            .search-wrap > .filter-list > div > div:last-child > .residency > div:nth-child(2) {
              margin-bottom: 40px; }
    .search-wrap > .search-help {
      background-color: #fff;
      width: 100%;
      height: 100vh;
      position: absolute;
      display: table;
      top: 55px;
      left: -100%;
      border-right: 1px solid #e0e0e0;
      border-top: 1px solid #e0e0e0;
      z-index: 997; }
      .search-wrap > .search-help > .help-content {
        margin-top: 13px;
        margin-left: 10px;
        width: calc(100% - 32px);
        height: 23px;
        line-height: 23px;
        display: flex;
        align-items: center; }
        .search-wrap > .search-help > .help-content > img {
          width: 16px;
          height: 16px;
          object-fit: contain;
          margin-right: 15px;
          float: left;
          vertical-align: middle; }
        .search-wrap > .search-help > .help-content > p {
          float: left;
          vertical-align: middle;
          font-size: 13px; }
    .search-wrap > .search-help[data-help-toggle="true"] {
      left: 0; }
    .search-wrap > .search-result {
      width: 100%;
      height: calc(100vh - 105px); }
      .search-wrap > .search-result > .search-result-left {
        float: none;
        display: block;
        width: 100%;
        height: calc(100%);
        position: relative; }
      .search-wrap > .search-result > .filterOn {
        display: none; }
      .search-wrap > .search-result > .searchON {
        display: none; }
      .search-wrap > .search-result > .search-result-right {
        height: 340px;
        float: right;
        width: 100%;
        background-color: #fff;
        border-left: 0px solid #e0e0e0;
        border-right: 0px solid #e0e0e0;
        position: fixed;
        top: auto;
        bottom: -280px;
        z-index: 996; }
        .search-wrap > .search-result > .search-result-right::after {
          display: block;
          content: "";
          clear: both; }
        .search-wrap > .search-result > .search-result-right > .arrow-btn {
          display: block;
          width: 48px;
          height: 24px;
          position: absolute;
          top: -22px;
          left: 0;
          right: 0;
          margin: auto;
          z-index: 997; }
          .search-wrap > .search-result > .search-result-right > .arrow-btn > .arrow {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 998;
            margin: auto; }
            .search-wrap > .search-result > .search-result-right > .arrow-btn > .arrow > img {
              display: block;
              margin: 0 auto;
              padding-top: 4px; }
        .search-wrap > .search-result > .search-result-right > .result-head {
          height: 50px;
          width: 100%;
          background-color: #fff;
          margin-bottom: 10px; }
          .search-wrap > .search-result > .search-result-right > .result-head > p {
            float: left;
            margin-top: 12px;
            margin-left: 16px;
            font-size: 14px;
            color: #323232; }
            .search-wrap > .search-result > .search-result-right > .result-head > p > span {
              font-weight: bold; }
          .search-wrap > .search-result > .search-result-right > .result-head > button {
            margin-top: 10px;
            margin-right: 16px;
            float: right;
            width: 104px;
            height: 36px;
            background-color: #fff;
            border: 1px solid #e0e0e0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            border-radius: 2px; }
            .search-wrap > .search-result > .search-result-right > .result-head > button > img {
              margin-right: 10px; }
          .search-wrap > .search-result > .search-result-right > .result-head > div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            text-align: center;
            vertical-align: middle;
            color: #16bdc6;
            font-size: 13px; }
            .search-wrap > .search-result > .search-result-right > .result-head > div > img {
              margin-right: 8px; }
        .search-wrap > .search-result > .search-result-right > .result-body {
          height: calc(100% - 120px);
          padding-bottom: 15px; }
          .search-wrap > .search-result > .search-result-right > .result-body > a {
            display: block;
            min-width: 416px;
            min-height: 120px;
            margin-bottom: 16px; }
            .search-wrap > .search-result > .search-result-right > .result-body > a:first-child ~ * {
              margin-top: 16px; }
            .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item {
              min-width: 416px;
              min-height: 120px; }
              .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item:nth-child(1) ~ * {
                margin-top: 16px; }
              .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-img {
                float: left;
                position: relative;
                width: 35%;
                max-width: 160px;
                height: 120px;
                border-radius: 2px;
                overflow: hidden;
                margin-right: 13px;
                cursor: pointer; }
                .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-img > .star {
                  width: 18px;
                  height: 18px;
                  position: absolute;
                  right: 8px;
                  top: 8px;
                  cursor: pointer; }
                .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-img > .vr {
                  width: 25px;
                  height: 25px;
                  position: absolute;
                  right: 39px;
                  top: 7px;
                  cursor: pointer; }
                .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-img > .add-compare {
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  height: 36px;
                  border-radius: 2px;
                  background-color: rgba(0, 0, 0, 0.3);
                  color: #fff;
                  transition: 0.5s all;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 13px;
                  border-radius: 2px; }
                  .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-img > .add-compare > img {
                    margin-right: 10px; }
              .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-img:hover > .add-compare {
                bottom: 0;
                transition: 0.5s all; }
              .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content {
                float: left;
                position: relative;
                width: 209px;
                height: 120px;
                border-radius: 2px;
                overflow: hidden; }
                .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content > .types > span {
                  font-size: 11px;
                  color: rgba(0, 0, 0, 0.3); }
                .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content > .item-title {
                  width: 100%;
                  font-size: 16px;
                  height: 23px;
                  font-weight: 500;
                  color: #323232;
                  display: block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis; }
                .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content > .item-location {
                  font-size: 12px;
                  font-weight: 300;
                  color: #828282;
                  height: 14px; }
                .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content > .item-price {
                  font-size: 12px;
                  font-weight: 300;
                  line-height: 21px;
                  color: #828282; }
                  .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content > .item-price > p {
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 21px;
                    color: #323232; }
                    .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content > .item-price > p > span {
                      font-size: 12px;
                      font-weight: 300;
                      line-height: 21px;
                      width: 20%; }
                    .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content > .item-price > p > span:first-child {
                      width: 20%;
                      display: inline-block; }
                    .search-wrap > .search-result > .search-result-right > .result-body > a > .result-item > .item-content > .item-price > p > span:last-child {
                      margin-left: 12px;
                      color: #828282; } }

.input-range__slider {
  appearance: none;
  background: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 1rem; }

.input-range__slider:active {
  transform: scale(1.3); }

.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2); }

.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none; }

.input-range__slider-container {
  transition: left 0.3s ease-out; }

.input-range__label {
  color: #aaaaaa;
  font-family: "Helvetica Neue", san-serif;
  font-size: 0.8rem;
  transform: translateZ(0);
  white-space: nowrap; }

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute; }

.input-range__label--min {
  left: 0; }

.input-range__label--max {
  right: 0; }

.input-range__label--value {
  position: absolute;
  top: -1.8rem; }

.input-range__label-container {
  left: -50%;
  position: relative; }

.input-range__label--max .input-range__label-container {
  left: 50%; }

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out; }

.input-range--disabled .input-range__track {
  background: #eeeeee; }

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%; }

.input-range__track--active {
  background: #3f51b5; }

.input-range {
  height: 1rem;
  position: relative;
  width: 100%; }
