p {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 15px;
    color: rgba(0, 0, 0, 0.65);
}
.commentModiInput{
  display: block;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 0.65);
  border : 1px solid #ccc;
}
button.replyBtn {
    display: inline-block;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    height: 24px;
    margin-right: 16px;
    color: rgba(0, 0, 0, 0.65);
}
.comment-data {
    display: inline-block;
    float: right;

    & > .nickname {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        vertical-align: middle;
    }
    & > .add-date {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.3);
        vertical-align: middle;
    }
    & > .nickname::after {
        content: "";
        margin-left: 9px;
        margin-right: 9px;
        border: 1px solid #e0e0e0;
    }
}
.re-comment {
    margin-top: 33px;
    & > div {
        display: inline-block;
        vertical-align: text-top;
        margin-right: 16px;
    }
    & > .re-comment-text {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 15px;
        color: rgba(0, 0, 0, 0.65);
    }

    & > button:nth-child(3) {
        margin-left: 34px;
    }

    & > button {
        display: inline-block;
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        height: 24px;
        margin-right: 16px;
        color: rgba(0, 0, 0, 0.65);
    }

    & > .comment-data {
        display: inline-block;
        float: right;
        margin-right: -10px;

        & > .nickname {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            vertical-align: middle;
        }
        & > .add-date {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.3);
            vertical-align: middle;
        }
        & > .nickname::after {
            content: "";
            margin-left: 9px;
            margin-right: 9px;
            border: 1px solid #e0e0e0;
        }
    }

    .re-comment {
        & > .add-re-comment {
            width: calc(100% - 34px);
            display: inline-block;
            vertical-align: text-top;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.65);
            margin-right: 0;
            & > .add-comment {
                max-width: 100%;
                width: 100%;
                margin-bottom: 8px;
                & > input {
                    width: calc(100% - 80px);
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    padding-left: 16px;
                    display: inline-block;
                    border: 1px solid #e0e0e0;
                    border-right: none;
                    border-radius: 3px 0 3px 0;
                    height: 56px;
                    vertical-align: middle;
                }
                & > button {
                    display: inline-block;
                    height: 56px;
                    width: 80px;
                    border: 1px solid #e0e0e0;
                    border-radius: 0px 3px 3px 3px;
                    background-color: #ffffff;
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    vertical-align: middle;
                }
            }
        }
    }

    & > .add-re-comment {
        width: calc(100% - 34px);
        display: inline-block;
        vertical-align: text-top;
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.65);
        margin-right: 0;
        & > .add-comment {
            max-width: 100%;
            width: 100%;
            margin-bottom: 8px;
            & > input {
                width: calc(100% - 80px);
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                padding-left: 16px;
                display: inline-block;
                border: 1px solid #e0e0e0;
                border-right: none;
                border-radius: 3px 0 3px 0;
                height: 56px;
                vertical-align: middle;
            }
            & > button {
                display: inline-block;
                height: 56px;
                width: 80px;
                border: 1px solid #e0e0e0;
                border-radius: 0px 3px 3px 3px;
                background-color: #ffffff;
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                vertical-align: middle;
            }
        }
    }
    & > .modi-re-comment {
        width: calc(100% - 34px);
        display: inline-block;
        vertical-align: text-top;
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.65);
        margin-right: 0;
        & > .modi-comment {
            max-width: 100%;
            width: 100%;
            margin-bottom: 8px;
            & > input {
                width: calc(100% - 80px);
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                padding-left: 16px;
                display: inline-block;
                border: 1px solid #e0e0e0;
                border-right: none;
                border-radius: 3px 0 3px 0;
                height: 56px;
                vertical-align: middle;
            }
            & > button {
                display: inline-block;
                height: 56px;
                width: 80px;
                border: 1px solid #e0e0e0;
                border-radius: 0px 3px 3px 3px;
                background-color: #ffffff;
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                vertical-align: middle;
            }
        }
    }
}

                        
                        
                    