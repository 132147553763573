@import "styles/_Common.scss";

.fixed-info {
  display: none;
  width: 384px;
  padding: 16px 16px 0 16px;
  & > .tag-box {
    & > span {
      font-size: 11px;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  & > h1 {
    font-weight: 500;
    font-size: 22px;
    padding-bottom: 11px;
    border-bottom: 1px solid #e0e0e0;
    width: 344px;
    border-bottom: 1px solid #e0e0e0;
    margin-top: 10px;
    margin-bottom: 31px;
  }
  & > .info-notice {
    font-size: 14px;
  }
  & > form {
    & > select {
      margin-top: 24px;
      width: 100%;
      height: 36px;
      background-color: #ffffff;
      border: 1px solid #e0e0e0;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.3);
      margin-bottom: 25px;
    }
    & > button {
      position: fixed;
      left: 0;
      bottom: 0;
      background: #4f4f4f;
      border-radius: 4px;
      height: 48px;
      width: 100%;
      color: #ffffff;
      z-index: 0;
      -webkit-transform: translate3d(0, 0, 0);
      // -webkit-transform: translateZ(0);
      // -webkit-backface-visibility: hidden;
    }
  }
  & > .util-buttons {
    position: relative;
    margin-top: 48px;
    margin-bottom: 60px;
    & > div {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border: 1px solid #e0e0e0;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      & > img {
          width: 22px;
          height: 22px;
          object-fit: contain;
          &.share{
            width: 28px;
            height: 28px;
          }
        }
    }
    & > div:nth-child(3):hover ~ .hover-util {
      width: 100%;
      transition: 0.5s all;
      border: 1px solid #e0e0e0;
    }
    & > .hover-util {
      border: none;
      transition: 0.5s all;
      background-color: #ffffff;
      position: absolute;
      max-width: 240px;
      width: 0%;
      overflow: hidden;
      left: 144px;
      & > a {
        margin: 0 11.5px;
      }
    }
    & > .hover-util:hover {
      border: 1px solid #e0e0e0;
      width: 100%;
    }
    & > div:not(last-child) {
      margin-right: 8px;
    }
    & > button {
      float: right;
      width: 104px;
      height: 36px;
      background-color: #fff;
      border: 1px solid #e0e0e0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      border-radius: 2px;
      & > img {
        margin-right: 10px;
        width: 28px;
        height: 28px;
        object-fit: contain;
      }
    }
  }
  .chating {
    // position: absolute;
    // top: 380px;
    display: none;
    width: 100%;
    height: calc(100vh);
    border: 1px solid #e0e0e0;
    background: #fafafa;
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    &.chatOn {
      display: block;
    }

    & > .chating-head {
      border-radius: 4px 4px 0px 0px;
      width: 100%;
      height: 72px;
      background-color: #16bdc6;
      position: relative;
      & > h1 {
        font-size: 18px;
        color: #ffffff;
        padding-top: 15px;
        text-align: center;
      }
      & > p {
        margin-top: 4px;
        font-size: 14px;
        color: #fafafa;
        text-align: center;
      }
      & > div {
        position: absolute;
        transform: rotate(-45deg);
        top: 10px;
        right: 10px;
        font-size: 24px;
        color: #fff;
        cursor: pointer;
      }
    }
    & > .date-selector {
      height: 64px;
      background: #fafafa;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      position: relative;
      & > .cal {
        position: absolute;
        top: 60px;
        z-index: 100;
      }
      & > span {
        font-size: 13px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.65);
        margin-right: 10px;
      }
      & > input {
        width: 95px;
        height: 36px;
        background-color: #fff;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.3);
        border: 1px solid #e0e0e0;
        line-height: 36px;
        padding-left: 12px;
        &.start-date {
          margin-left: 10px;
        }
        &.end-date {
          margin-left: 10px;
        }
      }
      & > .start-date::before {
        content: attr(data-placeholder);
        width: 100%;
      }

      & > input[type="date"]:focus::before,
      & > input[type="date"]:valid::before {
        display: none;
      }
    }

    & > .chat-body {
      width: 100%;
      height: calc(100% - 197px);
      overflow-y: scroll;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 0;
        background: none;
      }

      & > .user-chat {
        margin: 24px 0;
        position: relative;

        & > .chat {
          position: relative;
          margin-right: 24px;
          float: right;
          max-width: 212px;
          width: 100%;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
          border-radius: 6px 6px 0px 6px;
          white-space: normal;
          padding: 16px 12px;
          font-size: 14px;
          line-height: 22px;
          & > .chat-content {
            width: 90%;
          }
          & > .time-line {
            position: absolute;
            vertical-align: text-bottom;
            left: -70px;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.3);
          }
        }
      }

      & > .admin-chat {
        margin: 24px 0;
        position: relative;
        & > .chat-profile {
          display: inline-block;
          margin-left: 16px;
          margin-right: 8px;
          vertical-align: top;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          & > img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        & > .chat {
          display: inline-block;
          position: relative;
          margin-right: 24px;
          max-width: 212px;
          width: 100%;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
          border-radius: 0px 6px 6px 6px;
          white-space: normal;
          padding: 16px 12px;
          font-size: 14px;
          line-height: 22px;
          & > .chat-content {
            width: 90%;
          }
          & > .time-line {
            position: absolute;
            vertical-align: text-bottom;
            right: -66px;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.3);
          }
        }
        & > .chat2 {
          display: inline-block;
          position: relative;
          margin-right: 24px;
          max-width: 177px;
          width: 100%;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
          border-radius: 0px 6px 6px 6px;
          white-space: normal;
          padding: 16px 12px;
          font-size: 14px;
          line-height: 22px;
          & > .chat-content {
            width: 90%;
          }
          & > .time-line {
            position: absolute;
            vertical-align: text-bottom;
            right: -175px;
            bottom : 0;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.3);
          }
        }
      }
    }

    & > .chat-sender {
      background: #fafafa;
      padding-bottom: 16px;
      padding-top: 16px;
      & > form {
        & > input {
          margin-left: 12px;
          width: 276px;
          background: #ffffff;
          border: 1px solid #ecebed;
          border-radius: 18px;
          height: 36px;
          float: left;
          display: inline-block;
          padding-left: 27px;
          margin-right: 9px;
        }
        & > .icon {
          width: 36px;
          height: 36px;
          background-image: url("../../../../assets/images/Send.png");
          display: inline-block;
          cursor: pointer;
        }
      }
    }
  }
}

.primary-blue-btn {
  width: 58px;
  height: 20px;
  background: #16bdc6;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  line-height: 11px;
  margin-right: 8px;
}

.primary-vr-btn {
  width: 70px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 500;
  line-height: 11px;
  margin-top: 4px;
  float: right;
  & > img {
    margin-top: -5px;
    vertical-align: middle;
    margin-right: 9px;
    width: 14px;
    height: 14px;
    object-fit: contain;
  }
}

.nomination-btn {
  width: 30px;
  height: 20px;
  background: #fc9852;
  opacity: 0.8;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  line-height: 11px;
  margin-right: 5px;
}
@include media("<=medium", ">=small") {
  .fixed-info {
    display: block;
    width: 100%;
    & > .tag-box {
      & > span {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.4);
      }
    }
    & > h1 {
      font-weight: 500;
      font-size: 22px;
      padding-bottom: 11px;
      border-bottom: none;
      width: 344px;
      margin-top: 10px;
      margin-bottom: 0px;
    }
    & > .info-notice {
      font-size: 14px;
    }
    & > form {
      & > select {
        margin-top: 24px;
        width: 100%;
        height: 36px;
        background-color: #ffffff;
        border: 1px solid #e0e0e0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.3);
        margin-bottom: 25px;
      }
      & > button {
        background: #4f4f4f;
        border-radius: 4px;
        height: 48px;
        width: 100%;
        color: #ffffff;
        z-index: 998;
      }
    }
    & > .util-buttons {
      position: relative;
      margin-top: 5px;
      margin-bottom: 60 px;
      & > div {
        cursor: pointer;
        width: 40px;
        height: 40px;
        border: 1px solid #e0e0e0;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
      }
      & > div:nth-child(3):hover ~ .hover-util {
        width: 150px;
        transition: 0.5s all;
        border: 1px solid #e0e0e0;
      }
      & > .hover-util {
        border: none;
        transition: 0.5s all;
        background-color: #ffffff;
        position: absolute;
        max-width: 240px;
        width: 0%;
        overflow: hidden;
        left: 144px;
        & > a {
          margin: 0 11.5px;
        }
        & > button{
          & > img {
            width: 30px;
            height: 30px;
            object-fit: contain;
          }
        }
      }
      & > .hover-util:hover {
        border: 1px solid #e0e0e0;
        width: 100%;
      }
      & > div:not(last-child) {
        margin-right: 8px;
      }
      & > button {
        float: right;
        width: 104px;
        height: 36px;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        border-radius: 2px;
        & > img {
          width: 30px;
          height: 30px;
          object-fit: contain;
          margin-right: 10px;
        }
      }
    }
  }
  .chating {
    position: fixed;
    width: 100%;
    height: calc(100vh - 56px);
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
    border: 1px solid #e0e0e0;
    background: #fafafa;
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    display: none;
    -webkit-transform: translate3d(0, 0, 3px);
    &.chatOn {
      display: block;
    }
    & > .chating-head {
      border-radius: 4px 4px 0px 0px;
      width: 100%;
      height: 72px;
      background-color: #16bdc6;
      & > h1 {
        font-size: 18px;
        color: #ffffff;
        padding-top: 15px;
        text-align: center;
      }
      & > p {
        margin-top: 4px;
        font-size: 12px;
        color: #fafafa;
        text-align: center;
      }
    }
    & > .date-selector {
      height: 64px;
      background: #fafafa;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      & > span {
        font-size: 13px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.65);
        margin-right: 10px;
      }
      & > input {
        width: 90px;
        height: 36px;
        background-color: #fff;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.3);
        border: 1px solid #e0e0e0;
        line-height: 36px;
        padding-left: 12px;
      }
      & > .start-date::before {
        content: attr(data-placeholder);
        width: 100%;
      }

      & > input[type="date"]:focus::before,
      & > input[type="date"]:valid::before {
        display: none;
      }
    }

    & > .chat-body {
      display: block;
      height: 100%;
      & > .user-chat {
        margin: 24px 0;
        position: relative;

        & > .chat {
          position: relative;
          margin-right: 24px;
          float: right;
          max-width: 212px;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
          border-radius: 6px 6px 0px 6px;
          white-space: normal;
          padding: 10px 10px;
          font-size: 14px;
          line-height: 22px;
          & > span {
            display: inline-block;
            width: 50%;
          }
          & > .time-line {
            position: absolute;
            left: -52px;
            // top: 40px;
            bottom: 0;
            font-family: "Noto Sans KR";
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.3);
          }
        }
      }

      & > .admin-chat {
        margin: 24px 0;
        position: relative;
        & > .chat-profile {
          display: inline-block;
          margin-left: 16px;
          margin-right: 8px;
          vertical-align: top;
        }
        & > .chat {
          display: inline-block;
          position: relative;
          margin-right: 24px;
          max-width: 212px;
          width: 100%;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
          border-radius: 0px 6px 6px 6px;
          white-space: normal;
          padding: 16px 12px;
          font-size: 14px;
          line-height: 22px;
          & > span {
            display: inline-block;
            width: 50%;
          }
          & > .time-line {
            position: absolute;
            right: -52px;
            // top: 40px;
            bottom: 0;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.3);
          }
        }
        & > .chat2 {
          display: inline-block;
          position: relative;
          margin-right: 24px;
          max-width: 182px;
          width: 100%;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
          border-radius: 0px 6px 6px 6px;
          white-space: normal;
          padding: 16px 12px;
          font-size: 14px;
          line-height: 22px;
          & > span {
            display: inline-block;
            width: 90%;
          }
          & > .time-line {
            position: absolute;
            // top: 40px;
            right: -170px;
            bottom : 0;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.3);
          }
        }
      }
    }

    & > .chat-sender {
      background: #fafafa;
      padding-bottom: 16px;
      padding-top: 16px;
      position: absolute;
      bottom: 10px;
      width: 100%;
      & > form {
        display: block;
        overflow: hidden;
        width: 100%;
        margin: 0 auto;
        & > input.messageInput {
          margin-left: 12px;
          width: 75%;
          background: #ffffff;
          border: 1px solid #ecebed;
          border-radius: 18px;
          height: 36px;
          float: left;
          display: block;
          padding-left: 27px;
          margin-right: 9px;
        }
        & > .icon {
          width: 36px;
          height: 36px;
          background-image: url("../../../../assets/images/Send.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          display: block;
          float: left;
          cursor: pointer;
        }
      }
    }
  }
}
