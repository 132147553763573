* {
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
  font-family: "Noto Sans KR", sans-serif;
  letter-spacing: -0.4px;
  color: #323232;
}

a {
  color: initial;
  text-decoration: none;
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5 {
}

button,
input,
label {
  outline: none;
  cursor: pointer;
  background-color: transparent;
}

body,
html {
  max-width: 100vw;
}

.clear {
  display: block;
  clear: both;
}

.white {
  color: white;
}

.black {
  color: black;
}

.primary_blue {
  color: #16bdc6;
}
