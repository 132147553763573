@import "styles/_Common.scss";
div.errorWrap{
  width: 1080px;
  margin : 0 auto;
  min-height: 60vh;
  position: relative;
  &>div{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin : auto;
    width: 300px;
    height: 200px;
    &>h2{
      line-height: 80px;
    }
    &>a{
      display: block;
      width: fit-content;
      border: 1px solid #ccc;
      padding: 10px;
      margin-top: 20px;
    }
  }
}
@include media("<=medium", ">=small") {
  div.errorWrap{
    width: 100%;
  }
}