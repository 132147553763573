@import "styles/_Common.scss";

.mypage-wrap {
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;

  & > .mypage-user-data {
    padding-bottom: 50px;
    & > h1 {
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      margin-top: 48px;
      margin-bottom: 25px;
      text-align: center;
    }
    & > span {
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.65);
      text-align: center;
      display: block;
    }
  }

  & > .popularity {
    & > .content {
      max-width: 1122px;
      min-width: 1024px;
      margin: 0 auto;
      & > h2 {
        padding-top: 73px;
        padding-bottom: 23px;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        display: inline-block;
        vertical-align: middle;
      }
      & > button {
        width: 88px;
        height: 40px;
        border: 1px solid #e0e0e0;
        border-radius: 2px;
        background: #fff;
        float: right;
        vertical-align: middle;
        display: inline-block;
        margin-top: 72px;
      }

      & > .popularity-items {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        & > div {
          display: inline-block;
          min-height: 350px;
          & > .more-house {
            border: 1px solid #e0e0e0;
            border-radius: 2px;
            min-width: 256px;
            min-height: 350px;
            max-width: 256px;
            max-height: 350px;
            cursor: pointer;

            & > .more-content {
              margin-top: 114px;
              & > span {
                display: block;
                font-size: 16px;
                line-height: 23px;
                max-width: 144px;
                margin: 0 auto;
                color: rgba(0, 0, 0, 0.3);
                text-align: center;
              }
              & > button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 45px;
                height: 45px;
                background-color: #e0e0e0;
                border-radius: 50%;
                margin: 19px auto 0;
              }
            }
          }
          & > div {
            position: relative;
            float: left;
            max-width: 246px;
            height: calc(100% - 23px);
            margin: 16px;

            & > .check {
              width: 24px;
              height: 24px;
              background: rgba(255, 255, 255, 0.5);
              border-radius: 50%;
              position: absolute;
              left: 11px;
              top: 13px;
              cursor: pointer;
              line-height: 24px;
              text-align: center;
              & > img {
                opacity: 0;
              }
            }
            & > .check.active {
              transition: 0.5s all;
              width: 24px;
              height: 24px;
              background: #16bdc6;
              border-radius: 50%;
              position: absolute;
              box-shadow: inset 0px 1px 2px #2d9cdb;
              left: 11px;
              top: 13px;
              cursor: pointer;
              line-height: 24px;
              text-align: center;
              & > img {
                transition: 0.5s all;
                opacity: 1;
              }
            }

            & > img {
              position: absolute;
              top: 13px;
              right: 12px;
              cursor: pointer;
            }
            & > .image-box {
              width: 100%;
              max-height: 192px;
              border-radius: 6px;
              overflow: hidden;
            }
            & > .title {
              margin-top: 2px;
              width: 100%;
              font-weight: 500;
              font-size: 16px;
              line-height: 125%;
            }
            & > .location {
              width: 100%;
              margin-top: 20px;
              font-weight: 300;
              font-size: 12px;
              line-height: 17px;
              color: #828282;
            }
            & > .types {
              padding-top: 16px;
              & > button {
                padding: 6px;
                height: 20px;
                background: #16bdc6;
                border-radius: 2px;
                font-size: 11px;
                font-weight: 500;
                color: #fff;
                line-height: 11px;
                margin-right: 8px;
              }

              & > span {
                font-weight: 300px;
                font-size: 11px;
                color: #828282;
              }
            }

            & > .price {
              & > p {
                font-weight: 300;
                font-size: 12px;
                line-height: 21px;
                & > span:first-child {
                  display: inline-block;
                  width: 54px;
                  color: #828282;
                  margin-right: 37px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.mypage-category {
  width: 100%;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  & > .main-category {
    & > ul {
      max-width: 1122px;
      width: 100%;
      text-align: center;
      margin: 0 auto;
      & > li {
        display: inline-block;
        width: 20%;
        & > button {
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 53px;
          color: rgba(0, 0, 0, 0.3);
        }
      }
      & > .primary-blue {
        & > button {
          color: #16bdc6;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
        }
        border-bottom: 3px solid #16bdc6;
      }
    }
  }
  & > .sub-category {
    margin-top: 30px;
    & > ul {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      & > li {
        display: inline-block;
        width: 240px;
        margin-right: 24px;

        & > button {
          width: 100%;
          display: block;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          padding-bottom: 16px;
        }
        & > .primary_blue {
          border-bottom: 3px solid #16bdc6;
          color: #16bdc6;
        }
      }
    }
  }
}

// 아래부터는 모바일 반응형 임
@include media("<=medium", ">=small") {
  .mypage-wrap {
    width: 100%;
    margin: 0 auto;

    & > .mypage-user-data {
      width: unset;
      padding-bottom: 32px;
      & > h1 {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        margin-top: 32px;
        margin-bottom: 8px;
        text-align: center;
      }
      & > span {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
        display: block;
        text-align: center;
      }
    }

    & > .popularity {
      & > .content {
        max-width: 1122px;
        min-width: 1024px;
        margin: 0 auto;
        & > h2 {
          padding-top: 73px;
          padding-bottom: 23px;
          font-weight: 500;
          font-size: 20px;
          line-height: 29px;
          display: inline-block;
          vertical-align: middle;
        }
        & > button {
          width: 88px;
          height: 40px;
          border: 1px solid #e0e0e0;
          border-radius: 2px;
          background: #fff;
          float: right;
          vertical-align: middle;
          display: inline-block;
          margin-top: 72px;
        }

        & > .popularity-items {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          & > div {
            display: inline-block;
            min-height: 350px;
            & > .more-house {
              border: 1px solid #e0e0e0;
              border-radius: 2px;
              min-width: 256px;
              min-height: 350px;
              max-width: 256px;
              max-height: 350px;
              cursor: pointer;

              & > .more-content {
                margin-top: 114px;
                & > span {
                  display: block;
                  font-size: 16px;
                  line-height: 23px;
                  max-width: 144px;
                  margin: 0 auto;
                  color: rgba(0, 0, 0, 0.3);
                  text-align: center;
                }
                & > button {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 45px;
                  height: 45px;
                  background-color: #e0e0e0;
                  border-radius: 50%;
                  margin: 19px auto 0;
                }
              }
            }
            & > div {
              position: relative;
              float: left;
              max-width: 246px;
              height: calc(100% - 23px);
              margin: 16px;

              & > .check {
                width: 24px;
                height: 24px;
                background: rgba(255, 255, 255, 0.5);
                border-radius: 50%;
                position: absolute;
                left: 11px;
                top: 13px;
                cursor: pointer;
                line-height: 24px;
                text-align: center;
                & > img {
                  opacity: 0;
                }
              }
              & > .check.active {
                transition: 0.5s all;
                width: 24px;
                height: 24px;
                background: #16bdc6;
                border-radius: 50%;
                position: absolute;
                box-shadow: inset 0px 1px 2px #2d9cdb;
                left: 11px;
                top: 13px;
                cursor: pointer;
                line-height: 24px;
                text-align: center;
                & > img {
                  transition: 0.5s all;
                  opacity: 1;
                }
              }

              & > img {
                position: absolute;
                top: 13px;
                right: 12px;
                cursor: pointer;
              }
              & > .image-box {
                width: 100%;
                max-height: 192px;
                border-radius: 6px;
                overflow: hidden;
              }
              & > .title {
                margin-top: 2px;
                width: 100%;
                font-weight: 500;
                font-size: 16px;
                line-height: 125%;
              }
              & > .location {
                width: 100%;
                margin-top: 20px;
                font-weight: 300;
                font-size: 12px;
                line-height: 17px;
                color: #828282;
              }
              & > .types {
                padding-top: 16px;
                & > button {
                  padding: 6px;
                  height: 20px;
                  background: #16bdc6;
                  border-radius: 2px;
                  font-size: 11px;
                  font-weight: 500;
                  color: #fff;
                  line-height: 11px;
                  margin-right: 8px;
                }

                & > span {
                  font-weight: 300px;
                  font-size: 11px;
                  color: #828282;
                }
              }

              & > .price {
                & > p {
                  font-weight: 300;
                  font-size: 12px;
                  line-height: 21px;
                  & > span:first-child {
                    display: inline-block;
                    width: 54px;
                    color: #828282;
                    margin-right: 37px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .mypage-category {
    margin-left: 0px;
    width: unset;
    padding-top: 0px;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    & > .main-category {
      & > ul {
        width: 100%;
        text-align: left;
        margin: 0 0;
        display: flex;
        flex: auto;
        & > li {
          padding-top: 12px;
          display: inline-block;
          width: 100%;
          margin-right: 0px;
          text-align: center;
          & > button {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.3);
            white-space: nowrap;
          }
        }
        & > li:last-child {
          margin-right: 0;
        }
        & > li.primary-blue {
          padding-bottom: 5px;
          border-bottom: 3px solid #16bdc6;
          & > button {
            color: #16bdc6;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
          }
        }
      }
    }
    & > .sub-category {
      margin-top: 30px;
      & > ul {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        & > li {
          display: inline-block;
          width: 240px;
          margin-right: 24px;

          & > a {
            width: 100%;
            display: block;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            padding-bottom: 16px;
          }
          & > .primary_blue {
            border-bottom: 3px solid #16bdc6;
            color: #16bdc6;
          }
        }
      }
    }
  }
}
