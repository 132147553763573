@import "styles/_Common.scss";

.inquery {
  display: none;
  max-width: 768px;
  min-width: 320px;
  margin: 0 20px;
  & > .inquery_header {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 13px;
    padding-top: 12px;
    & > div {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > input.chk-box {
        position: absolute;
        opacity: 0;
        z-index: 9999999;
        width: 24px;
        height: 24px;
      }
      & > input.chk-box + label span {
        position: relative;
        display: inline-block;
        transform: translateY(50%);
        width: 24px;
        height: 24px;
        top: -18px;
        left: -2px;
        background: rgba(255, 255, 255, 0.3);
        border: 1px solid #e2e2e2;
        border-radius: 3px;
      }
      & > input.chk-box + label {
        line-height: 45px;
      }
      & > input.chk-box:checked + label span {
        background: #16bdc6;
        & > img {
          display: block;
          top: 6px;
          z-index: 11111;
          left: 4px;
        }
      }
      & > label > span > img {
        position: relative;
        display: none;
        top: -15px;
        left: 5px;
      }
      & > button {
        display: inline-block;
        vertical-align: middle;
        padding: 10px 12px 7px;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 2px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
      }
    }
  }
  & > .inquery_body {
    border-bottom: 1px solid #e0e0e0;
    .inquery_left {
      padding: 30px 0;
      float: left;
      & > div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > label {
          border: 2px solid #e0e0e0;
          width: 24px;
          height: 24px;
          border-radius: 2px;
          background-color: #fff;
        }
        & > input.chk-box {
          position: absolute;
          opacity: 0;
          z-index: 9999999;
          width: 24px;
          height: 24px;
        }
        & > label > span > img {
          position: relative;
          display: none;
          top: 0;
          left: 0;
        }
        & > input.chk-box + label span {
          position: relative;
          display: inline-block;
          transform: translateY(50%);
          width: 24px;
          height: 24px;
          top: -18px;
          left: -2px;
          background: rgba(255, 255, 255, 0.3);
          border: 1px solid #e2e2e2;
          border-radius: 3px;
        }
        & > input.chk-box + label {
          line-height: 45px;
        }
        & > input.chk-box:checked + label span {
          background: #16bdc6;
          & > img {
            display: block;
            top: 6px;
            left: 4px;
            z-index: 11111;
          }
        }
      }
    }
    .inquery_right {
      padding: 14px 0;
      padding-left: 15px;
      float: left;

      & > a {
        display: block;
        width: 100%;
        height: 100%;
        & > div:first-child {
          height: 24px;
          overflow: hidden;
          & > span {
            display: block;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            margin-right: 10px;
            width: 33%;
            height: 24px;
            float: left;
            &:first-child {
              width: 25%;
            }
            &.houseName {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          & > span:last-child {
            min-width: 70px;
            text-align: right;
            margin-right: 0;
          }
        }
        & > div:last-child {
          position: relative;
          display: inline-block;
          min-width: 250px;
          width: calc(100% - 5px);
          min-height: 19px;
          max-height: 38px;
          margin-top: 10px;
          padding-right: 30px;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          & > img {
            position: absolute;
            right: 0;
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}

@include media("<=medium", ">=small") {
  .inquery {
    max-width: 560px;
    width: 100%;
    margin: 0 auto;
    display: block;
    padding: 0 16px;
    & > .inquery_header {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 13px;
      padding-top: 12px;
      & > div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > label {
          border: 2px solid #e0e0e0;
          width: 24px;
          height: 24px;
          border-radius: 2px;
          background-color: #fff;
          & > input {
            opacity: 0;
          }
        }
        & > button {
          display: inline-block;
          vertical-align: middle;
          padding: 10px 12px 7px;
          background-color: #fff;
          border: 1px solid #e0e0e0;
          border-radius: 2px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
        }
      }
    }
    & > .inquery_body {
      border-bottom: 1px solid #e0e0e0;
      .inquery_left {
        padding: 30px 0;
        float: left;
        & > div {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          & > label {
            border: 2px solid #e0e0e0;
            width: 24px;
            height: 24px;
            border-radius: 2px;
            background-color: #fff;
            & > input {
              opacity: 0;
            }
          }
        }
      }
      .inquery_right {
        padding: 18px 0;
        padding-left: 15px;
        float: right;
        width: calc(100% - 24px);
        & > div:first-child {
          & > span {
            display: block;
            float: left;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            margin-right: 16px;
          }
          & > span:last-child {
            float: right;
            max-width: 70px;
            min-width: 70px;
            text-align: right;
            margin-right: 0;
            opacity: 0.65;
          }
        }
        & > div:first-child::after {
          content: "";
          display: block;
          clear: both;
        }
        & > div:last-child {
          margin-top: 4px;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          & > a {
            display: inline-block;
            width: 80%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            opacity: 0.65;
          }
        }
      }
    }
  }
}
