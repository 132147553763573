@import "styles/_Common.scss";
.chpw-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    max-width: 480px;
    min-width: 280px;
    height: 500px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    background-color: #fff;
    overflow: hidden;

    & > div {
      width: 100%;
      margin: 88px 0;

        & > .close {
            width: 24px;
            height: 24px;
            background-image: url(../../../assets/images/close.png);
            background-repeat: no-repeat;
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 999;
        }

        & > h1 {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 32px;
            display: block;
            text-align: center;
            padding-bottom: 8px;
        }
        & > p {
            display: block;
            text-align: center;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
        }
        & > form {
            margin: 0 auto;
            margin-top: 36px;
            width: 352px;
            & > input:first-child {
                margin-bottom: 8px;
            }

            & > input:nth-child(3) {
                margin-top: 8px;
            }

            & > input {
                display: block;
                width: 100%;
                height: 40px;
                padding-left: 11px;
                border: 1px solid #e0e0e0;
            }
            & > button {
                margin-top: 24px;
                background: #4f4f4f;
                border-radius: 3px;
                display: block;
                width: 100%;
                height: 48px;
                color: #fff;
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
            }
        }
    }
}
@include media("<=medium", ">=small") {
  .chpw-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 480px;
    min-width: 280px;
    height: 500px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    background-color: #fff;
    overflow: hidden;

    & > div {
      width: 100%;
      margin: 88px 0;

        & > .close {
            width: 24px;
            height: 24px;
            background-image: url(../../../assets/images/close.png);
            background-repeat: no-repeat;
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 999;
        }

        & > h1 {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 32px;
            display: block;
            text-align: center;
            padding-bottom: 8px;
        }
        & > p {
            display: block;
            text-align: center;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
        }
        & > form {
            margin: 0 auto;
            margin-top: 36px;
            width: 90%;
            margin : 0 auto;
            & > input:first-child {
                margin-bottom: 8px;
            }

            & > input:nth-child(3) {
                margin-top: 8px;
            }

            & > input {
                display: block;
                width: 100%;
                height: 40px;
                padding-left: 11px;
                border: 1px solid #e0e0e0;
            }
            & > button {
                margin-top: 24px;
                background: #4f4f4f;
                border-radius: 3px;
                display: block;
                width: 100%;
                height: 48px;
                color: #fff;
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
            }
        }
    }
}
}
