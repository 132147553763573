@import "styles/_Common.scss";
.m-footer {
    display: none;
    max-width: 768px;
    min-width: 320px;
    padding: 0 20px;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #e0e0e0;

    & > .content {
        & > .download-app {
            margin-top: 24px;
            margin-bottom: 25px;
            & > a:nth-child(1) {
                padding-right: 16px;
            }
             & > a{
              & > img{
                width: 118px;
                height: 40px;
                object-fit: contain;
              }
            }
        }
        & > .language-sns {
            & > .language {
                float: left;
                & > div {
                    display: inline-block;
                    padding-right: 24px;
                    height: 24px;
                    & > a {
                        display: table;
                        height: 100%;
                        & > img,
                        span {
                            display: table-cell;
                            vertical-align: middle;
                        }
                        & > span {
                            padding-left: 8px;
                            line-height: 24px;
                        }
                        & > img {
                          width: 24px;
                          height: 24px;
                          object-fit: contain;
                        }
                    }
                }
            }

            & > .sns {
                float: right;
                & > div {
                    display: inline-block;
                    padding-right: 26px;
                     & > a {
                      & > img {
                        width: 24px;
                        height: 24px;
                        object-fit: contain;
                      }
                    }
                }
                & > div:last-child {
                    padding-right: 0;
                }
            }
        }
        & > .company-info {
            & > h3 {
                padding-top: 27px;
                display: block;
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #000000;
            }
            & > ul {
                height: 18px;
                display: table;
                width: 100%;
                padding-top: 16px;

                & > li {
                    position: relative;
                    display: inline-block;
                    padding-right: 10px;
                    padding-left: 10px;
                    vertical-align: middle;
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: 550;
                    font-size: 13px;
                    line-height: 11px;
                    border-right: 1px solid #323232;
                }
                & > li:first-child {
                    padding-left: 0;
                }

                & > li:last-child {
                    border-right: none;
                }
            }

            & > .copy {
                padding-top: 6px;
                margin-bottom: 55px;
                & > p {
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 13px;
                    line-height: 24px;
                    color: #000000;
                    & > span {
                        font-size: 12.5px;
                    }
                }
            }
        }
    }
}

@include media("<=medium", ">=small") {
    .m-footer {
        display: block;
    }
}
