@import "styles/_Common.scss";

.content {
    width: 1120px;
    margin: 0 auto;
    & > h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 35px;
        color: #323232;
        text-align: center;
        margin: 39px 0;
    }
    & > .terms {
        padding-bottom: 100px;
        margin: 0 auto;
        max-width: 730px;
        width: 100%;
        & > p {
            font-size: 14px;
            font-weight: 300;
            color: #323232;
            margin-top: 14px;
            max-width: 100%;
            white-space: pre-wrap;
        }

        & > .little-title {
            font-size: 16px;
            font-weight: 400;
            color: #262626;
            margin-top: 0;
        }
        & > .title {
            font-size: 18px;
            font-weight: 700;
            color: #323232;
            margin-top: 0;
        }
    }
}

.index {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    position: fixed;
    top: 200px;
    left: 160px;
}

@include media("<=medium", ">=small") {
    .content {
        width: 92%;
        max-width: 400px;
        margin: 0 auto;
        & > h1 {
            font-weight: bold;
            font-size: 16px;
            line-height: 35px;
            color: #323232;
            text-align: center;
            margin: 39px 0;
        }
        & > .terms {
            padding-bottom: 100px;
            margin: 0 auto;
            width: 100%;
            & > p {
                font-size: 12px;
                font-weight: 300;
                color: #323232;
                margin-top: 14px;
                max-width: 100%;
                white-space: pre-wrap;
            }

            & > .little-title {
                font-size: 14px;
                font-weight: 400;
                color: #262626;
                margin-top: 0;
            }
            & > .title {
                font-size: 16px;
                font-weight: 700;
                color: #323232;
                margin-top: 0;
            }
        }
    }

    .index {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        position: fixed;
        top: 200px;
        left: 160px;
    }
}
