@import "styles/_Common.scss";

header {
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #e8e8e8;
  & > .content {
    height: 72px;
    width: 100%;
    padding: 0 48px;
    position: relative;

    & > .ham-menu {
      width: 40px;
      height: 40px;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      justify-content: center;
      align-items: center;
      position: relative;
      float: left;
      top: 50%;
      transform: translateY(-50%);
      display: none;
      flex-wrap: wrap;
      padding: 0 11px;

      & > .line {
        width: 18px;
        margin: 2px 0;
        border: 1px solid #323232;
        height: 0px;
      }
    }
    & > .ham-menu::after {
      content: "";
      position: absolute;
      border: 1px solid #323232;
    }
    & > .ham-menu::before {
      content: "";
      position: absolute;
      border: 1px solid #323232;
    }

    & > nav {
      display: table;
      float: left;
      height: 100%;
      & > a {
        display: table-cell;
        padding: 0 16px;
        vertical-align: middle;
        cursor: pointer;
      }
    }

    & > h2 {
      position: absolute;
      top: 38px;
      left: 50%;
      transform: translate(-50%, -50%);
      float: left;

      & > a {
        display: block;
        & > img {
          height: 40px;
        }
      }
    }

    & > .search-icon {
      display: none;
    }

    & > .member-wrap {
      height: 100%;
      display: table;
      float: right;

      & > a {
        display: table-cell;
        vertical-align: middle;
        padding: 0 32px;
        cursor: pointer;
      }
    }
  }
}
@include media("<=medium", ">=small") {
  header {
    max-width: 768px;
    // min-width: 380px;
    width: 100%;
    &.positionFix {
      position: fixed;
      top: 0;
    }
    & > .content {
      height: 56px;
      width: 100%;
      padding: 0;
      position: relative;

      & > nav {
        display: none;
        float: left;
        margin-left: 64px;
        height: 100%;
        & > a {
          display: table-cell;
          padding: 0 32px;
          vertical-align: middle;
          cursor: pointer;
        }
      }

      & > .ham-menu {
        display: flex;
        left: 8px;
      }

      & > h2 {
        position: absolute;
        top: 32px;
        left: 50%;
        transform: translate(-50%, -50%);
        float: left;
        width: 143px;
        height: auto;

        & > a {
          max-width: 100%;
          display: block;
          & > img {
            max-width: 100%;
            height: auto;
          }
        }
      }

      & > .search-icon {
        position: absolute;
        top: 30px;
        left: unset;
        right: 15px;
        transform: translate(-50%, -50%);
        display: block;
        width: unset;
        height: unset;
        float: unset;

        & > a {
          display: inline-block;
          width: 20px;
          height: 20px;
          & > img {
            width: 100%;
            height: 100%;
          }
        }
      }

      & > .member-wrap {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: right;
        margin-right: 3px;
        & > a {
          display: none;
          vertical-align: middle;
          padding: 0 32px;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
        }
      }
    }
  }
}
