@import "styles/_Common.scss";
.chpw-wrap {
    margin: 0 auto;
    & > div {
        padding-top: 184px;
        padding-bottom: 253px;

        & > h1 {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 32px;
            display: block;
            text-align: center;
            padding-bottom: 40px;
        }
        & > p {
            display: block;
            text-align: center;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            margin-bottom: 70px;
        }

        & > button {
            margin: 0 auto;
            width: 352px;
            margin-top: 24px;
            background: #4f4f4f;
            border-radius: 3px;
            display: block;
            height: 48px;
            color: #fff;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
        }
    }
}

@include media("<=medium", ">=small") {
  .chpw-wrap {
    margin: 0 auto;
    & > div {
        padding-top: 40px;
        padding-bottom: 40px;

        & > h1 {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            display: block;
            text-align: center;
            padding-bottom: 40px;
        }
        & > p {
            display: block;
            text-align: center;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            margin-bottom: 70px;
            padding : 0 10px;
        }

        & > button {
            margin: 0 auto;
            width: 90%;
            margin-top: 24px;
            background: #4f4f4f;
            border-radius: 3px;
            display: block;
            height: 48px;
            color: #fff;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
        }
    }
}

}
