@import "styles/_Common.scss";

.base {
    width: 100%;
    min-width: 1152px;
    & > .toTop {
      display: none;
    }
}

@include media("<=medium", ">=small") {
    .base {
        width: 100vw;
        min-width: 100vw;
        max-width: 100vw;
        overflow-x: hidden;
        & > .goPrev {
          position: fixed;
          bottom: 32px; left: 10px;
          width: 48px;
          height: 48px;
          background: rgba(0, 0, 0, 0.3);
          border-radius: 50%;
          z-index: 999999;
          & > p {
            position: absolute;
            top: 24px; left: 16px;
            transform: rotate(-45deg);
            opacity: 0.7;
            & > span {
              position: absolute;
              display: block;
              background: #fff;
            }
            & > span:first-child {
              top: 0px; left: 0px;
              width: 16px; height: 1px;
            }
            & > span:last-child {
              top: 0px; left: 0px;
              width: 1px; height: 16px;
            }
          }
        }
        & > .toTop {
          width: 48px;
          height: 48px;
          border-radius: 32px;
          position: fixed;
          bottom: 32px;
          right: 10px;
          display: none;
          z-index: 999999;
          & > img {
            width: 100%;
          }
        }
    }
}
