@import "styles/_Common.scss";
.Account-management {
    width: 550px;
    margin: 0 auto;
    & > div {
        & > .UserAccount-left {
            float: left;
            min-height: 120px;
            // background: url("../../../assets/images/login_background.png");
            background-position: right;
            margin: 50px 0;
            box-sizing: border-box;
            padding: 5px 0;
            & > h1 {
                width: 200px;
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                color: #323232;
                float: right;
                & > span {
                    font-size: 28px;
                    font-weight: bold;
                }
            }

            & > p {
                font-size: 18px;
                line-height: 26px;
                color: #323232;
                margin-left: 352px;
                margin-top: 17px;
            }
        }

        & > .UserAccount-left:nth-child(3) {
            position: relative;
            margin: 0;
            // margin-top: 20px;
            & > .liner {
                position: absolute;
                top: 369px;
                right: -112px;
                width: 191px;
            }
        }

        & > .UserAccount-right:nth-child(4) {
            margin-top: 0;
            margin-bottom: 24px;
            & > div {
                & > .member_type {
                  & > div {
                    text-align: left;
                  }
                }
                & > .naver-linked-account {
                    margin: 0 0 16px 0;
                    & > .sns-type {
                        display: block;
                        float: left;
                        padding: 13px 0;
                        font-family: Noto Sans KR;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        border: 1px solid #e0e0e0;
                        border-radius: 3px;
                        background-color: transparent;
                        width: 100px;

                        vertical-align: middle;
                        & > img {
                            margin-right: 3px;
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }
                    & > span {
                        display: block;
                        float: left;
                        font-family: Noto Sans KR;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        color: rgba(0, 0, 0, 0.4);
                        display: inline-block;
                        text-align: left;
                        padding: 8px;
                    }

                    & > span.has-linked {
                        color: #000;
                    }

                    & > button {
                        display: block;
                        float: right;
                        width: 64px;
                        height: 40px;
                        border: 1px solid #e0e0e0;
                        border-radius: 2px;
                        background-color: transparent;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 13px;
                    }
                }

                & > .kakao-linked-account {
                    margin: 16px 0;
                    & > .sns-type {
                        display: block;
                        float: left;
                        padding: 13px 0;
                        font-family: Noto Sans KR;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        border: 1px solid #e0e0e0;
                        border-radius: 3px;
                        background-color: transparent;
                        width: 100px;

                        vertical-align: middle;
                        & > img {
                            margin-right: 3px;
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }
                    & > span {
                        display: block;
                        float: left;
                        font-family: Noto Sans KR;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        color: rgba(0, 0, 0, 0.4);
                        display: inline-block;
                        text-align: left;
                        padding: 8px;
                    }

                    & > span.has-linked {
                        color: #000;
                    }

                    & > button {
                        display: block;
                        float: right;
                        width: 64px;
                        height: 40px;
                        border: 1px solid #e0e0e0;
                        border-radius: 2px;
                        background-color: transparent;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 13px;
                    }
                }

                & > .facebook-linked-account {
                    margin: 16px 0;
                    & > .sns-type {
                        display: block;
                        float: left;
                        padding: 13px 0;
                        font-family: Noto Sans KR;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        border: 1px solid #e0e0e0;
                        border-radius: 3px;
                        background-color: transparent;
                        width: 100px;

                        vertical-align: middle;
                        & > img {
                            margin-right: 3px;
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }
                    & > span {
                        display: block;
                        float: left;
                        font-family: Noto Sans KR;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        color: rgba(0, 0, 0, 0.4);
                        display: inline-block;
                        text-align: left;
                        padding: 8px;
                    }

                    & > span.has-linked {
                        color: #000;
                    }

                    & > button {
                        display: block;
                        float: right;
                        width: 64px;
                        height: 40px;
                        border: 1px solid #e0e0e0;
                        border-radius: 2px;
                        background-color: transparent;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 13px;
                    }
                }
                & > .receive-agreement {
                  margin-top: 80px;
                  overflow: hidden;
                    & > div {
                        margin: 12px 0;
                        float: left;
                        vertical-align: middle;
                        & > label {
                            margin-right: 10px;
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            border: 1px solid #e0e0e0;
                            background-color: transparent;
                            vertical-align: bottom;
                            text-align: center;
                            line-height: 16px;
                            & > input[type="checkbox"] {
                                display: none;
                            }
                        }

                        & > span {
                            line-height: 19px;
                            display: inline-block;
                            font-family: Noto Sans KR;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            vertical-align: middle;
                        }
                    }
                }
                & > .close-account {
                    display: block;
                    float: right;
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    margin-top: 40px;
                }
            }
        }
        & > .UserAccount-right {
            float: left;
            width: 50%;
            min-height: 120px;
            margin: 50px 0;
            & > div {
                text-align: center;
                width: 352px;
                & > h1 {
                    font-size: 24px;
                    line-height: 35px;
                    margin-bottom: 6px;
                }
                & > form {
                    & > input::placeholder {
                        font-size: 13px;
                        color: rgba(0, 0, 0, 0.3);
                    }
                    & > .change-password {
                        width: 350px;
                        border: 1px solid #e0e0e0;
                        border-radius: 3px;
                        font-family: Noto Sans KR;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        background-color: transparent;
                        line-height: 0px;
                        color: #444444;
                        line-height: 40px;
                        margin: 8px 0;
                    }
                    & > input:read-only {
                      background-color: rgb(248, 248, 248);
                    }
                    & > input,
                    select {
                        width: 352px;
                        border: 1px solid #e0e0e0;
                        background-color: #fff;
                        margin: 8px 0;
                        height: 40px;
                        font-size: 13px;
                        padding-left: 8px;
                        & > option[data-default] {
                            font-size: 13px;
                            color: rgba(0, 0, 0, 0.3);
                        }
                    }
                    & > select {
                        -webkit-appearance: none;
                    }
                    & > .genders {
                        & > label {
                            margin: 8px 0;
                            display: inline-block;
                            width: 50%;
                            box-sizing: border-box;
                            border-radius:3px;
                            & > input[type="radio"] {
                                display: none;
                            }
                            & > input[type="radio"]:checked ~ .gender-box {
                                background: #16bdc6;
                                border-color: #16bdc6;
                                border-radius:3px;
                                color: #fff;
                                transition: 0.5s all;
                            }
                            & > .gender-box {
                                background-color: #fff;
                                border-radius: 3px;
                                font-size: 13px;
                                line-height: 40px;
                                height: 40px;
                                text-align: center;
                                border: 1px solid #e0e0e0;
                                transition: 0.5s all;
                            }
                        }
                    }
                    & > p {
                        text-align: left;
                        margin-bottom: 31px;
                        font-size: 13px;
                        font-weight: 300;
                        color: #323232;
                    }
                    & > label {
                        display: table;
                        & > input {
                            display: inline-block;
                            vertical-align: middle;
                            border: 1px solid #e0e0e0;
                            width: 16px;
                            height: 16px;
                            margin-right: 10px;
                            background-color: #fff;
                        }
                        color: #323232;
                        font-size: 13px;
                        font-weight: 300;
                    }

                    & > button {
                        background: #4f4f4f;
                        width: 352px;
                        height: 48px;
                        text-align: center;
                        line-height: 48px;
                        color: #fff;
                    }

                    & > div:last-child {
                        padding-top: 24px;

                        & > span {
                            margin-right: 165px;
                            font-size: 12px;
                        }
                        & > a {
                            font-size: 12px;
                        }
                    }
                }

                
            }
        }
        & > .confirm-change {
            border-top: 1px solid #e0e0e0;
            margin-bottom: 60px;
            width: 100%;
            max-width: 1200px;
            & > button {
                margin-top: 23px;
                width: 154px;
            }
            & > .confirm-ok {
                background: #4f4f4f;
                border-radius: 3px;
                padding: 14px 12px;
                float: right;
                color: #fff;
            }
            & > .confirm-cancel {
                margin-right: 12px;
                background: #fff;
                border-radius: 3px;
                padding: 14px 12px;
                border: 1px solid #e0e0e0;
                float: right;
            }
        }
    }
}

@include media("<=medium", ">=small") {
    .Account-management {
        max-width: 320px;
        margin: 0 auto;
        width: 100%;
        padding: 0 16px;
        & > div {
            & > .UserAccount-left {
                margin: 0 auto;
                min-height: 20px;
                width: 100%;
                max-width: 320px;
                background-position: right;
                & > h1 {
                    margin-right: 0px;
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    color: #323232;
                    float: left;
                    margin-top: 24px;
                    margin-bottom: 14px;
                    & > span {
                        font-size: 28px;
                        font-weight: bold;
                    }
                }

                & > p {
                    font-size: 18px;
                    line-height: 26px;
                    color: #323232;
                    margin-left: 352px;
                    margin-top: 17px;
                }
            }

            & > .UserAccount-left:nth-child(3) {
                position: relative;
                margin: 0 auto;
                & > .liner {
                    display: none;
                    position: absolute;
                    top: 401px;
                    right: -112px;
                    width: 191px;
                    border-bottom: 1px solid #e0e0e0;
                }
                & > h1 {
                    margin-right: 0;
                    margin-top: 0px;
                }
            }

            & > .UserAccount-right:nth-child(4) {
              margin-top: 0;
              min-height: 200px;
              height: 200px;
                & > div {
                    margin: 0 auto;
                    max-width: 320px;
                    & > .naver-linked-account {
                        margin-bottom: 16px;
                        & > .sns-type {
                            padding: 13px 0;
                            font-family: Noto Sans KR;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            border: 1px solid #e0e0e0;
                            border-radius: 3px;
                            background-color: transparent;
                            width: 90px;

                            vertical-align: middle;
                            & > img {
                                margin-right: 3px;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }
                        & > span {
                            font-family: Noto Sans KR;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            color: rgba(0, 0, 0, 0.4);
                            display: inline-block;
                        }

                        & > span.has-linked {
                            color: #000;
                        }

                        & > button {
                            width: 44px;
                            height: 40px;
                            border: 1px solid #e0e0e0;
                            border-radius: 2px;
                            background-color: transparent;
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            line-height: 13px;
                        }
                    }

                    & > .kakao-linked-account {
                        margin-bottom: 16px;
                        & > .sns-type {
                            padding: 13px 0;
                            font-family: Noto Sans KR;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            border: 1px solid #e0e0e0;
                            border-radius: 3px;
                            background-color: transparent;
                            width: 90px;

                            vertical-align: middle;
                            & > img {
                                margin-right: 3px;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }
                        & > span {
                            font-family: Noto Sans KR;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            color: rgba(0, 0, 0, 0.4);
                            display: inline-block;
                        }

                        & > span.has-linked {
                            margin-right: 28px;
                        }

                        & > button {
                            width: 44px;
                            height: 40px;
                            border: 1px solid #e0e0e0;
                            border-radius: 2px;
                            background-color: transparent;
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            line-height: 13px;
                        }
                    }

                    & > .facebook-linked-account {
                        margin-bottom: 16px;
                        & > .sns-type {
                            padding: 13px 0;
                            font-family: Noto Sans KR;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            border: 1px solid #e0e0e0;
                            border-radius: 3px;
                            background-color: transparent;
                            width: 90px;

                            vertical-align: middle;
                            & > img {
                                margin-right: 3px;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }
                        & > span {
                            font-family: Noto Sans KR;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            color: rgba(0, 0, 0, 0.4);
                            display: inline-block;
                        }

                        & > span.has-linked {
                            margin-right: 28px;
                        }

                        & > button {
                            width: 44px;
                            height: 40px;
                            border: 1px solid #e0e0e0;
                            border-radius: 2px;
                            background-color: transparent;
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            line-height: 13px;
                        }
                    }
                    & > .receive-agreement {
                      margin-top: 60px;
                        & > div {
                            float: left;
                            vertical-align: middle;
                            margin-bottom: 15px;
                            & > label {
                                margin-right: 10px;
                                display: inline-block;
                                width: 16px;
                                height: 16px;
                                border: 1px solid #e0e0e0;
                                background-color: transparent;
                                vertical-align: bottom;
                                & > input[type="checkbox"] {
                                    display: none;
                                }
                            }
                            & > span {
                                line-height: 19px;
                                display: inline-block;
                                font-family: Noto Sans KR;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 13px;
                                vertical-align: middle;
                            }
                        }
                        & > div:last-child {
                            margin-bottom: 24px;
                        }
                    }
                    & > .close-account {
                        display: block;
                        float: right;
                        font-family: Noto Sans KR;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        margin-top: 25px;
                        margin-bottom: 25px;
                    }
                }
                    
            }
            & > .confirm-change {
                border-top: 1px solid #e0e0e0;
                width: 100%;
                max-width: 500px;
                margin: 0 auto;
                margin-bottom: 60px;
                margin-top: 40px;
                & > button {
                    margin-top: 23px;
                    width: 48%;
                }
                & > .confirm-ok {
                    background: #4f4f4f;
                    border-radius: 3px;
                    padding: 16px 12px;
                    float: right;
                    color: #fff;
                }
                & > .confirm-cancel {
                    margin-right: 11px;
                    background: #fff;
                    border-radius: 3px;
                    padding: 16px 12px;
                    border: 1px solid #e0e0e0;
                    float: left;
                }
            }
            & > .UserAccount-right {
                float: right;
                width: 100%;
                min-height: 372px;
                margin: 0;
                & > div {
                    text-align: center;
                    width: 100%;
                    // min-width: 300px;
                    max-width: 500px;
                    margin-left: 0px;
                    margin-top: 0px;
                    margin: 0 auto;
                    & > h1 {
                        font-size: 24px;
                        line-height: 35px;
                        margin-bottom: 6px;
                    }
                    & > form {
                        max-width: 768px;
                        // min-width: 300px;

                        & > input::placeholder {
                            font-size: 13px;
                            color: rgba(0, 0, 0, 0.3);
                        }
                        & > .change-password {
                            width: 100%;
                            border: 1px solid #e0e0e0;
                            border-radius: 3px;
                            font-family: Noto Sans KR;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            background-color: transparent;
                            line-height: 0px;
                            color: #444444;
                            line-height: 40px;
                            margin: 8px 0;
                        }
                        & > input,
                        select {
                            width: 100%;
                            border: 1px solid #e0e0e0;
                            margin: 8px 0;
                            height: 40px;
                            font-size: 13px;
                            padding-left: 8px;
                            & > option[data-default] {
                                font-size: 13px;
                                color: rgba(0, 0, 0, 0.3);
                            }
                        }
                        & > select {
                            -webkit-appearance: none;
                        }
                        & > .genders {
                            & > label {
                                margin: 8px 0;
                                display: inline-block;
                                width: 50%;
                                box-sizing: border-box;
                                & > input[type="radio"] {
                                    display: none;
                                }
                                & > input[type="radio"]:checked ~ .gender-box {
                                    background: #16bdc6;
                                    border-color: #16bdc6;
                                    border-radius: 3px 0px 0px 3px;
                                    color: #fff;
                                    transition: 0.5s all;
                                }
                                & > .gender-box {
                                    background-color: #fff;
                                    border-radius: 3px;
                                    font-size: 13px;
                                    line-height: 40px;
                                    height: 40px;
                                    text-align: center;
                                    border: 1px solid #e0e0e0;
                                    transition: 0.5s all;
                                }
                            }
                        }
                        & > p {
                            text-align: left;
                            margin-bottom: 31px;
                            font-size: 13px;
                            font-weight: 300;
                            color: #323232;
                        }
                        & > label {
                            display: table;
                            & > input {
                                display: inline-block;
                                vertical-align: middle;
                                border: 1px solid #e0e0e0;
                                width: 16px;
                                height: 16px;
                                margin-right: 10px;
                                background-color: #fff;
                            }
                            color: #323232;
                            font-size: 13px;
                            font-weight: 300;
                        }

                        & > button {
                            background: #4f4f4f;
                            width: 352px;
                            height: 48px;
                            text-align: center;
                            line-height: 48px;
                            color: #fff;
                        }

                        & > div:last-child {
                            padding-top: 24px;

                            & > span {
                                margin-right: 165px;
                                font-size: 12px;
                            }
                            & > a {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}
