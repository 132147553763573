.cs-edit-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: 5px solid #ccc;
}
.cs-edit-content ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.cs-edit-content a {
  color: #16bdc6;
}
.banner-empty-div {
  height: 50px;
  margin-bottom: 46px;
}

.image-gallery.fullscreen-modal .image-gallery-content {
  height: 100%;
}

.image-gallery-content {
  position: static;
}

.image-gallery-slide-wrapper {
  background-color: black;
}

.image-gallery-slide:focus {
  outline: none;
}
.image-gallery-icon:focus {
  outline: none;
}

@media screen and (min-width: 320px) {
  .image-gallery {
    z-index: 999;
    overflow-y: hidden;
    -webkit-transform: translateZ(5px);
  }

  .banner-empty-div {
    margin-bottom: 16px;
  }
}
