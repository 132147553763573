@import "styles/_Common.scss";

.m-inhabi {
    display: none;
    max-width: 768px;
    min-width: 320px;
    width: 100%;
    background-color: #e0e0e0;
    padding-bottom: 40px;
    justify-content: center;
    margin-top: 24px;
    & > .content {
        margin: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 96%;
        max-width: 400px;
        & > h2 {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            padding-top: 38px;
            padding-bottom: 20px;
        }
        & > a:hover {
            background: #f2f2f2;
        }
        & > a {
            display: inline-block;
            background-color: #fff;
            height: 158px;
            width: calc( 50% - 12px);
            margin: 6px;
            cursor: pointer;
            & > div {
                & > h3 {
                    display: inline-block;
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: bold;
                    margin-top: 16px;
                    margin-left: 24px;
                    & > span {
                        position: relative;
                        font-weight: normal;
                        font-size: 16px;
                    }
                    & > span::after {
                        content: "";
                        position: absolute;
                        width: 16px;
                        border: 2px solid #000000;
                        bottom: -16px;
                        left: 0;
                    }
                }
                & > p {
                    margin-top: 21px;
                    margin-left: 24px;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 20px;
                    color: rgba(0, 0, 0, 0.4);
                }
            }
        }
        

        & > a:last-child {
            width: calc( 100% - 12px );
            background: url("../../../assets//images/cat_2.png"), #fff;
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: 80px;
        }
        & > a:last-child:hover {
            background: url("../../../assets//images/cat_2.png"), #f2f2f2;
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: 80px;
        }

        & > a:nth-child(odd) div {
            margin-left: 6px;
        }
        & > a:nth-child(even) div {
            margin-right: 6px;
        }
    }
}

@include media("<=medium", ">=small") {
    .m-inhabi {
        display: flex;
    }
}
