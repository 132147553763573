@import "styles/_Common.scss";

.inquiryDetail-wrap {
  max-width: 940px;
  min-width: 940px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 102px;
  & > div {
    margin-top: 64px;
    & > h1 {
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      color: rgba(0, 0, 0, 0.3);
      & > span {
        color: #323232;
        font-weight: bold;
      }
    }
  }
  & > .Detail-left {
    float: left;
    & > .map {
      max-width: 544px;
      min-width: 544px;
      max-height: 296px;
      height: 296px;
    }
    & > .info-card {
      margin-top: 16px;
      padding: 32px;
      border: 1px solid #e0e0e0;
      & > .result-item {
        margin-bottom: 23px;
        min-height: 120px;
        min-width: 416px;
        & > .item-img {
          float: left;
          position: relative;
          width: 160px;
          height: 120px;
          border-radius: 2px;
          overflow: hidden;
          margin-right: 13px;
          cursor: pointer;
          & > .house_thumb {
            height: 100%;
          }
          & > .star {
            position: absolute;
            right: 8px;
            top: 8px;
            cursor: pointer;
          }
          & > .vr {
            position: absolute;
            right: 39px;
            top: 7px;
            cursor: pointer;
          }

          & > .add-compare {
            position: absolute;
            bottom: -36px;
            width: 100%;
            height: 36px;
            border-radius: 2px;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
            transition: 0.5s all;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            border-radius: 2px;
            & > img {
              margin-right: 10px;
            }
          }
        }
        & > .item-img:hover {
          & > .add-compare {
            bottom: 0;
            transition: 0.5s all;
          }
        }
        & > .item-content {
          float: left;
          position: relative;
          // width: 209px;
          width: 60%;
          height: 120px;
          border-radius: 2px;
          overflow: hidden;
          & > .types {
            & > span {
              font-size: 11px;
              color: rgba(0, 0, 0, 0.3);
            }
          }
          & > .item-title {
            width: 100%;
            margin-top: 11px;
            font-size: 16px;
            height: 23px;
            font-weight: 500;
            color: #323232;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          & > .item-location {
            font-size: 12px;
            font-weight: 300;
            color: #828282;
            height: 14px;
          }

          & > .item-price {
            font-size: 12px;
            font-weight: 300;
            line-height: 21px;
            color: #828282;
            & > p {
              margin-bottom: 0;
              font-size: 12px;
              font-weight: 300;
              line-height: 21px;
              color: #323232;
              & > span {
                font-size: 12px;
                font-weight: 300;
                line-height: 21px;
              }
              & > span:first-child {
                width: 58px;
                display: inline-block;
              }
              & > span:last-child {
                margin-left: 12px;
                color: #828282;
              }
            }
          }
        }
      }

      & > .phonecall {
        margin-top: 24px;
        width: 100%;
        padding: 20px 0;
        background-color: #fafafa;
        overflow: hidden;
        vertical-align: middle;
        & > img {
          margin-left: 29px;
          display: block;
          float: left;
          padding-top: 5px;
        }
        & > p {
          display: block;
          margin-left: 30px;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          float: left;
          margin-bottom: 0;
          line-height: 40px;
          & > span {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: #16bdc6;
            & > b {
              color: #16bdc6;
            }
          }
        }
      }
      & > span {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
      }
    }
  }
  & > .Detail-right {
    margin-left: 32px;
    float: left;
    height: 627px;
    overflow: hidden;
    & > .chating {
      width: 353px;
      border: 1px solid #e0e0e0;
      background: #fafafa;
      border-radius: 4px 4px 0px 0px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

      & > .chating-head {
        border-radius: 4px 4px 0px 0px;
        width: 100%;
        height: 72px;
        background-color: #16bdc6;
        & > h1 {
          font-size: 18px;
          color: #ffffff;
          padding-top: 15px;
          text-align: center;
        }
        & > p {
          margin-top: 4px;
          font-size: 12px;
          color: #fafafa;
          text-align: center;
        }
      }
      & > .date-selector {
        padding: 24px 0;
        background: #fafafa;
        justify-content: left;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        position: relative;
        height: 130px;
        & > div{
          display: flex;
          flex-wrap: wrap;
          & > span {
            display: block;
            width: 85px;
            text-align: left;
            font-size: 13px;
            color: rgba(0, 0, 0, 0.65);
            text-align: center;
            line-height: 41px;
          }
          & > .middle{
            width: 10px;
            text-align: center;
          }
  
          & > .room-title {
            width: 150px;
            text-align: center;
          }
  
          & > .room {
            width: 50%;
            text-align: left;
          }
  
          & > .date-title {
            width: 150px;
            text-align: center;
          }
          &.day{
            & > span {
              &.date-title{
                text-align: center;
              }
              &.middle{
                width: 20px;
              }
              text-align: start;
            }
          }
        }
        & > .cal {
          position: absolute;
          top: 120px;
          z-index: 100;
        }


        & > .start-date::before {
          content: attr(data-placeholder);
          width: 100%;
        }

        & > input[type="date"]:focus::before,
        & > input[type="date"]:valid::before {
          display: none;
        }
      }

      & > .chat-body {
        // padding-bottom: 84px;
        height: 400px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 5px;
        }
        & > .user-chat {
          margin: 24px 0;
          position: relative;
          overflow: hidden;

          & > .chat {
            position: relative;
            margin-right: 24px;
            float: right;
            max-width: 212px;
            width: 100%;
            background: #ffffff;
            border: 1px solid #e0e0e0;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
            border-radius: 6px 6px 0px 6px;
            white-space: normal;
            padding: 16px 12px;
            font-size: 14px;
            line-height: 22px;
            & > .time-line {
              position: absolute;
              left: -80px;
              // top: 20px;
              bottom: 0;
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 22px;
              color: rgba(0, 0, 0, 0.3);
            }
          }
        }

        & > .admin-chat {
          margin: 24px 0;
          position: relative;
          & > .chat-profile {
            display: inline-block;
            width: 40px;
            height: 40px;
            margin-left: 16px;
            margin-right: 8px;
            vertical-align: top;
            & > img {
              width: 100%;
              height: 100%;
              border-radius: 15px;
            }
          }
          & > .chat {
            display: inline-block;
            position: relative;
            margin-right: 24px;
            max-width: 212px;
            width: 100%;
            background: #ffffff;
            border: 1px solid #e0e0e0;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
            border-radius: 0px 6px 6px 6px;
            white-space: normal;
            padding: 16px 12px;
            font-size: 14px;
            line-height: 22px;
            & > .time-line {
              position: absolute;
              right: -60px;
              // top: 40px;
              bottom: 0;
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 22px;
              color: rgba(0, 0, 0, 0.3);
            }
          }
        }
      }

      & > .chat-sender {
        background: #fafafa;
        padding-bottom: 16px;
        padding-top: 16px;
        margin-top: 20px;
        & > form {
          & > input {
            margin-left: 12px;
            width: 276px;
            background: #ffffff;
            border: 1px solid #ecebed;
            border-radius: 18px;
            height: 36px;
            float: left;
            display: inline-block;
            padding-left: 27px;
            margin-right: 9px;
          }
        }
        & > .icon {
          width: 36px;
          height: 36px;
          background-image: url("../../../../../../assets/images/Send.png");
          display: inline-block;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          cursor: pointer;
        }
      }
    }
  }
}

.primary-blue-btn {
  width: 58px;
  height: 20px;
  background: #16bdc6;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  line-height: 11px;
  margin-right: 8px;
}

.nomination-btn {
  width: 30px;
  height: 20px;
  background: #fc9852;
  opacity: 0.8;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  line-height: 11px;
  margin-right: 5px;
}

@include media("<=medium", ">=small") {
  .inquiryDetail-wrap {
    max-width: 768px;
    min-width: auto;
    width: 100%;
    margin: 0 0px;
    padding-bottom: 0;
    height: calc(100vh - 53px);
    overflow: hidden;
    // height: 100%;
    & > div {
      width: 100%;
      margin-top: 0px;
      & > h1 {
        width: 100%;
        text-align: center;
        padding: 0;
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.3);
        height: 40px;
        line-height: 40px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        & > span {
          color: #323232;
          font-weight: bold;
        }
      }
    }
    & > .Detail-left {
      float: left;
      & > .map {
        display: none;
      }
      & > .info-card {
        margin-top: 0px;
        padding: 0px;
        border: 0;
        border-top: 1px solid #e0e0e0;
        & > .result-item {
          display: none;
          margin-bottom: 23px;
          min-height: 120px;
          min-width: 416px;
          & > .item-img {
            float: left;
            position: relative;
            width: 160px;
            height: 120px;
            border-radius: 2px;
            overflow: hidden;
            margin-right: 13px;
            cursor: pointer;
            & > .star {
              position: absolute;
              right: 8px;
              top: 8px;
              cursor: pointer;
            }
            & > .vr {
              position: absolute;
              right: 39px;
              top: 7px;
              cursor: pointer;
            }

            & > .add-compare {
              position: absolute;
              bottom: -36px;
              width: 100%;
              height: 36px;
              border-radius: 2px;
              background-color: rgba(0, 0, 0, 0.3);
              color: #fff;
              transition: 0.5s all;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 13px;
              border-radius: 2px;
              & > img {
                margin-right: 10px;
              }
            }
          }
          & > .item-img:hover {
            & > .add-compare {
              bottom: 0;
              transition: 0.5s all;
            }
          }
          & > .item-content {
            float: left;
            position: relative;
            width: 209px;
            height: 120px;
            border-radius: 2px;
            overflow: hidden;
            & > .types {
              & > span {
                font-size: 11px;
                color: rgba(0, 0, 0, 0.3);
              }
            }
            & > .item-title {
              margin-top: 11px;
              font-size: 16px;
              height: 23px;
              font-weight: 500;
              color: #323232;
              display: block;
            }
            & > .item-location {
              font-size: 12px;
              font-weight: 300;
              color: #828282;
              height: 14px;
            }

            & > .item-price {
              font-size: 12px;
              font-weight: 300;
              line-height: 21px;
              color: #828282;
              & > p {
                font-size: 12px;
                font-weight: 300;
                line-height: 21px;
                color: #323232;
                & > span {
                  font-size: 12px;
                  font-weight: 300;
                  line-height: 21px;
                }
                & > span:first-child {
                  width: 58px;
                  display: inline-block;
                }
                & > span:last-child {
                  margin-left: 12px;
                  color: #828282;
                }
              }
            }
          }
        }

        & > .phonecall {
          margin-top: 0px;
          width: 100%;
          padding: 10px 0;
          background-color: #fafafa;
          height: 40px;
          display: flex;
          justify-content: center;
          & > img {
            margin-left: 0;
            display: inline-block;
            height: 20px;
            width: auto;
            object-fit: contain;
          }
          & > p {
            display: inline-block;
            margin-left: 19px;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
            & > span {
              display: inline-block;
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              color: #16bdc6;
              & > b {
                margin-left: 15px;
                color: #16bdc6;
              }
            }
          }
        }
        & > span {
          display: none;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
        }
      }
    }
    & > .Detail-right {
      margin-left: 0px;
      float: left;
      height: calc(100% - 80px);
      overflow: hidden;
      // position: fixed;
      // bottom: 0;
      & > .chating {
        min-width: auto;
        height: 100%;
        width: unset;
        border: none;
        background: #fafafa;
        border-radius: 4px 4px 0px 0px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

        & > .chating-head {
          border-radius: 4px 4px 0px 0px;
          width: 100%;
          height: 72px;
          background-color: #16bdc6;
          & > h1 {
            font-size: 18px;
            color: #ffffff;
            padding-top: 15px;
            text-align: center;
          }
          & > p {
            margin-top: 4px;
            font-size: 12px;
            color: #fafafa;
            text-align: center;
          }
        }
        & > .date-selector {
          padding: 0;
          // padding-top: 14px;
          background: #fafafa;
          justify-content: left;
          align-items: center;
          flex-wrap: wrap;
          border-bottom: 1px solid #e0e0e0;
          height: 60px;
          overflow: hidden;
          & > div{
             padding: 0px 0;
            // padding-top: 14px;
            background: #fafafa;
            display: flex;
            justify-content: left;
            align-items: center;
            flex-wrap: wrap;
            height: 30px;
            line-height: 30px;
            & > span {
              display: block;
              min-width: 80px;
              width: 30%;
              font-size: 13px;
              color: rgba(0, 0, 0, 0.65);
              line-height: 30px;
              &.room-title{
                min-width: 100px;
                width: 22%;
                line-height: 30px;
              }
              &.date-title{
                min-width: 100px;
                width: 22%;
              }
              &.middle{
                min-width: auto;
              }
            }
          }

          & > .start-date::before {
            content: attr(data-placeholder);
            width: 100%;
          }

          & > input[type="date"]:focus::before,
          & > input[type="date"]:valid::before {
            display: none;
          }
        }

        & > .chat-body {
          // padding-bottom: 20px;
          height: calc(100% - 50px);
          & > .user-chat {
            margin: 24px 0;
            position: relative;

            & > .chat {
              position: relative;
              margin-right: 24px;
              float: right;
              max-width: 212px;
              width: 100%;
              background: #ffffff;
              border: 1px solid #e0e0e0;
              box-sizing: border-box;
              box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
              border-radius: 6px 6px 0px 6px;
              white-space: normal;
              padding: 16px 12px;
              font-size: 14px;
              line-height: 22px;
              & > .time-line {
                position: absolute;
                left: -70px;
                // top: 30px;
                bottom: 0;
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 22px;
                height: 22px;
                color: rgba(0, 0, 0, 0.3);
              }
            }
          }

          & > .admin-chat {
            margin: 24px 0;
            position: relative;
            & > .chat-profile {
              display: inline-block;
              margin-left: 16px;
              margin-right: 8px;
              vertical-align: top;
            }
            & > .chat {
              display: inline-block;
              position: relative;
              margin-right: 24px;
              max-width: 180px;
              width: 100%;
              background: #ffffff;
              border: 1px solid #e0e0e0;
              box-sizing: border-box;
              box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
              border-radius: 0px 6px 6px 6px;
              white-space: normal;
              padding: 16px 12px;
              font-size: 14px;
              line-height: 22px;
              & > .time-line {
                position: absolute;
                right: -70px;
                // top: 30px;
                bottom: 0;
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 22px;
                height: 22px;
                color: rgba(0, 0, 0, 0.3);
              }
            }
          }
        }

        & > .chat-sender {
          position: fixed;
          bottom: 0;
          width: 100%;
          background: #fafafa;
          padding-bottom: 0;
          // padding-top: 16px;
          margin-top: 0;
          overflow: hidden;
          & > form {
            width: calc(100% - 70px);
            overflow: hidden;
            float: left;
            & > input {
              margin-left: 12px;
              min-width: 255px;
              width: calc(100% - 70px);
              background: #ffffff;
              border: 1px solid #ecebed;
              border-radius: 18px;
              height: 36px;
              float: left;
              display: block;
              padding-left: 27px;
              margin-right: 9px;
            }
          }
          & > .icon {
            width: 36px;
            height: 36px;
            background-image: url("../../../../../../assets/images/Send.png");
            display: block;
            cursor: pointer;
            float: left;
          }
        }
      }
    }
  }

  .primary-blue-btn {
    width: 58px;
    height: 20px;
    background: #16bdc6;
    border-radius: 2px;
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    line-height: 11px;
    margin-right: 8px;
  }

  .nomination-btn {
    width: 30px;
    height: 20px;
    background: #fc9852;
    opacity: 0.8;
    border-radius: 2px;
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    line-height: 11px;
    margin-right: 5px;
  }
}
