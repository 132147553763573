@import "styles/_Common.scss";
.modp {
  width: 100%;
  height: 55vh;
  background-color: #ffffff;
  & > .modp-wrap {
    position: relative;
    top: 0;
    transform: translateY(50%);
    width: 352px;
    margin: 0 auto;
    text-align: center;
    background-color: #ffffff;
    & > h1 {
      font-size: 32px;
      margin-top: 42px;
      margin-bottom: 8px;
    }
    & > span {
      display: block;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.3);
      margin-bottom: 24px;
      text-align: left;
    }

    & > form:nth-child(3) {
      margin-top: 24px;
      margin-bottom: 63px;

      & > div {
        margin-top: 16px;
        margin-bottom: 62px;
        & > label {
          margin-right: 92px;
        }
        & > div::after {
          content: "";
          position: absolute;
          width: 10px;
          border: 1px solid #e0e0e0;
          transform: rotate(90deg) translate(-50%, -50%);
          top: 70%;
          left: 55%;
        }
        & > label,
        div {
          position: relative;
          display: inline-block;
          font-weight: 300;
          font-size: 12px;
          & > input[type="checkbox"] {
            display: inline-block;
            vertical-align: middle;
            width: 16px;
            height: 16px;
            border: 1px solid #e0e0e0;
            margin-right: 10px;
            background-color: #ffffff;
          }
          & > a:first-child {
            margin-right: 28px;
          }
          & > a:last-child {
            color: #16bdc6;
          }
        }
      }

      & > input[type="submit"] {
        background-color: #4f4f4f;
        border-radius: 3px;
        text-align: center;
        line-height: 48px;
        height: 48px;
        width: 352px;
        font-weight: 500;
        font-size: 15px;
        margin-top: 10px;
        color: #ffffff;
      }
      & > label {
        position: relative;
        & > input {
          width: 352px;
          border: 1px solid #e0e0e0;
          margin: 8px 0;
          height: 40px;
          font-size: 13px;
          padding-left: 8px;
        }
        & > img {
          position: absolute;
          top: 50%;
          left: 88px;
          transform: translateY(-50%);
        }
      }
    }
  }
}
@include media("<=medium", ">=small") {
  .modp {
  width: 100%;
  height: 55vh;
  background-color: #ffffff;
  & > .modp-wrap {
    position: static;
    top: auto;
    transform: translateY(0);
    width: 90%;
    margin: 0 auto;
    text-align: center;
    background-color: #ffffff;
    & > h1 {
      font-size: 24px;
      margin-top: 42px;
      margin-bottom: 8px;
    }
    & > span {
      display: block;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.3);
      margin-bottom: 24px;
      text-align: left;
      width: 90%;
      margin : 0 auto;
    }

    & > form:nth-child(3) {
      margin-top: 24px;
      margin-bottom: 63px;

      & > div {
        margin-top: 16px;
        margin-bottom: 62px;
        & > label {
          margin-right: 92px;
        }
        & > div::after {
          content: "";
          position: absolute;
          width: 10px;
          border: 1px solid #e0e0e0;
          transform: rotate(90deg) translate(-50%, -50%);
          top: 20%;
          left: 55%;
        }
        & > label,
        div {
          position: relative;
          display: inline-block;
          font-weight: 300;
          font-size: 12px;
          & > input[type="checkbox"] {
            display: inline-block;
            vertical-align: middle;
            width: 16px;
            height: 16px;
            border: 1px solid #e0e0e0;
            margin-right: 10px;
            background-color: #ffffff;
          }
          & > a:first-child {
            margin-right: 28px;
          }
          & > a:last-child {
            color: #16bdc6;
          }
        }
      }

      & > input[type="submit"] {
        background-color: #4f4f4f;
        border-radius: 3px;
        text-align: center;
        line-height: 48px;
        height: 48px;
        width: 90%;
        font-weight: 500;
        font-size: 15px;
        margin-top: 10px;
        color: #ffffff;
      }
      & > label {
        position: relative;
        & > input {
          width: 90%;
          border: 1px solid #e0e0e0;
          margin: 8px 0;
          height: 40px;
          font-size: 13px;
          padding-left: 8px;
        }
        & > img {
          position: absolute;
          top: 50%;
          left: 88px;
          transform: translateY(-50%);
        }
      }
    }
  }
}
}
