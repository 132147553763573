@import "styles/_Common.scss";

.mypage-user-data {
  padding-bottom: 50px;
  & > h1 {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    margin-top: 48px;
    margin-bottom: 25px;
    text-align: center;
  }
  & > span {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    display: block;
  }
}

.mypage-category {
  width: 100%;
  padding-top: 15px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  & > .main-category {
    & > ul {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      & > li {
        display: inline-block;
        max-width: 224px;
        width: 100%;
        margin-right: 24px;
        & > a {
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          color: rgba(0, 0, 0, 0.3);
        }
      }
      & > li.primary-blue {
        padding-bottom: 13px;
        & > a {
          color: #16bdc6;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
        }
        border-bottom: 3px solid #16bdc6;
      }
    }
  }
  & > .sub-category {
    margin-top: 30px;
    & > ul {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      & > li {
        display: inline-block;
        width: 240px;
        margin-right: 24px;

        & > a {
          width: 100%;
          display: block;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          padding-bottom: 16px;
        }
        & > .primary_blue {
          border-bottom: 3px solid #16bdc6;
          color: #16bdc6;
        }
      }
    }
  }
}
.popularity {
  min-height: 700px;
  margin-bottom: 24px;

  & > .content {
    max-width: 1122px;
    min-width: 1024px;
    margin: 0 auto;

    & > h2 {
      padding-top: 40px;
      padding-bottom: 8px;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 16px;
      & > input[id="chk-all"] {
        display: none;
      }
      & > input[id="chk-all"] + label em {
        position: relative;
        display: inline-block;
        transform: translateY(50%);
        width: 24px;
        height: 24px;
        margin-right: 16px;
        background: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 50%;
      }
      & > input[id="chk-all"] + label {
        line-height: 45px;
      }
      & > input[id="chk-all"]:checked + label em {
        background: #16bdc6;
      }
      & > label > em > img {
        position: relative;
        top: -15px;
        left: 5px;
      }
    }
    & > button {
      width: 88px;
      height: 40px;
      border: 1px solid #e0e0e0;
      border-radius: 2px;
      background: #fff;
      float: right;
      vertical-align: middle;
      display: inline-block;
      margin-top: 40px;
      margin-bottom: 8px;
      margin-right: 32px;
    }

    & > .popularity-items {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      & > div:nth-child(even) {
        & > div:nth-child(even) {
          margin-right: 0;
        }
      }
      & > div:nth-child(odd) {
        & > div:nth-child(odd) {
          margin-left: 0;
        }
      }
      & > div {
        display: inline-block;
        min-height: 350px;

        & > .more-house {
          border: 1px solid #e0e0e0;
          border-radius: 2px;
          min-width: 256px;
          min-height: 350px;
          max-width: 256px;
          max-height: 350px;
          cursor: pointer;

          & > .more-content {
            margin-top: 114px;
            & > span {
              display: block;
              font-size: 16px;
              line-height: 23px;
              max-width: 144px;
              margin: 0 auto;
              color: rgba(0, 0, 0, 0.3);
              text-align: center;
            }
            & > button {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 45px;
              height: 45px;
              background-color: #e0e0e0;
              border-radius: 50%;
              margin: 19px auto 0;
            }
          }
        }
        & > div {
          position: relative;
          float: left;
          max-width: 246px;
          height: calc(100% - 23px);
          margin: 16px;

          & > .check {
            width: 24px;
            height: 24px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 50%;
            position: absolute;
            left: 11px;
            top: 13px;
            cursor: pointer;
            line-height: 24px;
            text-align: center;
            & > img {
              opacity: 0;
            }
          }
          & > .check.active {
            transition: 0.5s all;
            width: 24px;
            height: 24px;
            background: #16bdc6;
            border-radius: 50%;
            position: absolute;
            box-shadow: inset 0px 1px 2px #2d9cdb;
            left: 11px;
            top: 13px;
            cursor: pointer;
            line-height: 24px;
            text-align: center;
            & > img {
              transition: 0.5s all;
              opacity: 1;
            }
          }

          & > img {
            position: absolute;
            top: 13px;
            right: 12px;
            cursor: pointer;
          }
          & > .image-box {
            width: 100%;
            max-height: 192px;
            border-radius: 2px;
            overflow: hidden;
          }
          & > .title {
            margin-top: 2px;
            width: 100%;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            height: 30px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          & > .location {
            width: 100%;
            margin-top: 20px;
            font-weight: 300;
            font-size: 12px;
            line-height: 17px;
            color: #828282;
          }
          & > .types {
            padding-top: 16px;
            & > button {
              padding: 6px;
              height: 20px;
              background: #16bdc6;
              border-radius: 2px;
              font-size: 11px;
              font-weight: 500;
              color: #fff;
              line-height: 11px;
              margin-right: 8px;
            }

            & > span {
              font-weight: 300px;
              font-size: 11px;
              color: #828282;
            }
          }

          & > .price {
            & > p {
              font-weight: 300;
              font-size: 12px;
              line-height: 21px;
              margin-bottom: 0;
              & > span:first-child {
                display: inline-block;
                width: 54px;
                color: #828282;
                margin-right: 37px;
              }
            }
          }
        }
      }
    }
  }
}

.recently-viewed-items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-wrap: wrap;

  & > div {
    position: relative;
    min-height: 350px;
    display: inline-block;
    & > div {
      position: relative;
      float: left;
      max-width: 245px;
      height: calc(100% - 24px);
      margin: 16px 16px 40px 16px;
      cursor: pointer;
      & > .image-box {
        width: 100%;
        max-height: 192px;
        border-radius: 2px;
        overflow: hidden;
        & > img {
          width: 100%;
          height: auto;
        }
      }
      & > .title {
        margin-top: 6px;
        width: 100%;
        font-family: "Noto Sans KR";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        height: 30px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      & > .location {
        width: 100%;
        margin-top: 7px;
        font-weight: 300;
        font-size: 12px;
        line-height: 17px;
        color: #828282;
        margin-bottom: 5px;
      }
      & > .types {
        padding-top: 16px;
        & > button {
          padding: 6px;
          height: 20px;
          background: #16bdc6;
          border-radius: 2px;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          font-weight: 500;
          color: #fff;
          line-height: 9px;
          margin-right: 14px;
        }

        & > span {
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: #828282;
        }
      }

      & > .price {
        & > p {
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          line-height: 21px;
          margin-bottom: 0;
          & > span:first-child {
            display: inline-block;
            width: 54px;
            color: #828282;
            margin-right: 6px;
          }
          & > span:last-child {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            color: #323232;
          }
        }
      }
    }
    & > h3 {
      position: absolute;
      & > input.chk-box {
        position: absolute;
        display: none;
      }
      & > input.chk-box + label em {
        position: relative;
        display: inline-block;
        transform: translateY(50%);
        width: 24px;
        height: 24px;
        margin: 18px 27px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 50%;
      }
      & > input.chk-box + label {
        line-height: 45px;
      }
      & > input.chk-box:checked + label em {
        background: #16bdc6;
        & > img {
          display: block;
          top: 6px;
          z-index: 11111;
        }
      }
      & > label > em > img {
        position: relative;
        display: none;
        top: -15px;
        left: 5px;
      }
    }
    & > img {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
      width: 22px;
      height: 22px;
      object-fit: contain;
    }
  }

  & > .more-house {
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    min-width: 256px;
    min-height: 350px;
    max-width: 256px;
    max-height: 350px;

    cursor: pointer;

    & > .more-content {
      margin-top: 114px;
      height: calc(100% - 130px);
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      & > span {
        display: block;
        font-size: 16px;
        line-height: 23px;
        max-width: 144px;
        margin: 0 auto;
        color: rgba(0, 0, 0, 0.3);
        text-align: center;
      }
      & > button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
        background-color: #e0e0e0;
        border-radius: 50%;
        margin: 19px auto 0;
      }
    }
  }
}

@include media("<=medium", ">=small") {
  .recently-viewed-items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-wrap: wrap;
    & > div:nth-child(even) {
      & > div:nth-child(even) {
        margin-right: 0;
      }
    }

    & > div {
      width: 100%;
      min-height: 350px;
      display: inline-block;
      margin-bottom: 32px;

      & > div {
        position: relative;
        float: left;
        max-width: 100%;
        width: 100%;
        height: calc(100% - 24px);
        margin: 0px;
        cursor: pointer;
        & > img {
          position: absolute;
          top: 13px;
          right: 12px;
          cursor: pointer;
        }
        & > .image-box {
          width: 100%;
          max-height: 480px;
          min-height: 240px;
          border-radius: 2px;
          overflow: hidden;
          & > img {
            width: 100%;
            height: auto;
          }
        }
        & > .title {
          margin-top: 6px;
          width: 100%;
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 125%;
          height: 40px;
          overflow: hidden;
        }
        & > .location {
          width: 100%;
          margin-top: 7px;
          font-weight: 300;
          font-size: 12px;
          line-height: 17px;
          color: #828282;
          margin-bottom: 5px;
        }
        & > .types {
          padding-top: 8px;
          & > button {
            padding: 6px;
            height: 20px;
            background: #16bdc6;
            border-radius: 2px;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            font-weight: 500;
            color: #fff;
            line-height: 9px;
            margin-right: 14px;
          }

          & > span {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            color: #828282;
          }
        }

        & > .price {
          & > p {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 21px;
            & > span:first-child {
              display: inline-block;
              width: 54px;
              color: #828282;
              margin-right: 6px;
            }
            & > span:last-child {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              color: #323232;
            }
          }
        }
      }
    }

    & > .more-house {
      border: 1px solid #e0e0e0;
      border-radius: 2px;
      min-width: 256px;
      min-height: 350px;
      max-width: 256px;
      max-height: 350px;

      cursor: pointer;

      & > .more-content {
        margin-top: 114px;
        height: calc(100% - 130px);
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        & > span {
          display: block;
          font-size: 16px;
          line-height: 23px;
          max-width: 144px;
          margin: 0 auto;
          color: rgba(0, 0, 0, 0.3);
          text-align: center;
        }
        & > button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 45px;
          height: 45px;
          background-color: #e0e0e0;
          border-radius: 50%;
          margin: 19px auto 0;
        }
      }
    }
  }

  .mypage-user-data {
    padding-bottom: 50px;
    & > h1 {
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      margin-top: 48px;
      margin-bottom: 25px;
      text-align: center;
    }
    & > span {
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.65);
      text-align: center;
      display: block;
    }
  }

  .mypage-category {
    width: 100%;
    padding-top: 15px;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    & > .main-category {
      & > ul {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        & > li {
          display: inline-block;
          max-width: 224px;
          width: 100%;
          margin-right: 24px;
          & > a {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            color: rgba(0, 0, 0, 0.3);
          }
        }
        & > li.primary-blue {
          padding-bottom: 13px;
          & > a {
            color: #16bdc6;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
          }
          border-bottom: 3px solid #16bdc6;
        }
      }
    }
    & > .sub-category {
      margin-top: 30px;
      & > ul {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        & > li {
          display: inline-block;
          width: 240px;
          margin-right: 24px;

          & > a {
            width: 100%;
            display: block;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            padding-bottom: 16px;
          }
          & > .primary_blue {
            border-bottom: 3px solid #16bdc6;
            color: #16bdc6;
          }
        }
      }
    }
  }
  .popularity {
    max-width: 430px;
    width: calc(100% - 32px);
    display: block;
    margin: 0 auto;
    & > .content {
      max-width: 768px;
      min-width: auto;
      width: 100%;
      & > h2 {
        padding-top: 20px;
        padding-bottom: 20px;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 0;
        overflow: hidden;

        & > label {
          line-height: 48px !important;
        }
      }
      & > button {
        width: 88px;
        height: 40px;
        border: 1px solid #e0e0e0;
        border-radius: 2px;
        background: #fff;
        float: right;
        vertical-align: middle;
        display: inline-block;
        margin-top: 20px;
        margin-right: 0;
      }

      & > .popularity-items {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        & > div {
          display: inline-block;
          min-height: unset;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          width: 100%;
          & > .more-house {
            border: 1px solid #e0e0e0;
            border-radius: 2px;
            min-width: 320px;
            min-height: 350px;
            max-width: 320px;
            max-height: 350px;
            cursor: pointer;

            & > .more-content {
              margin-top: 114px;
              & > span {
                display: block;
                font-size: 16px;
                line-height: 23px;
                max-width: 144px;
                margin: 0 auto;
                color: rgba(0, 0, 0, 0.3);
                text-align: center;
              }
              & > button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 45px;
                height: 45px;
                background-color: #e0e0e0;
                border-radius: 50%;
                margin: 19px auto 0;
              }
            }
          }

          & > div {
            position: relative;
            float: left;
            max-width: 320px;
            height: unset;
            margin: 0px;
            margin-bottom: 34px;

            & > .check {
              width: 24px;
              height: 24px;
              background: rgba(255, 255, 255, 0.5);
              border-radius: 50%;
              position: absolute;
              left: 11px;
              top: 13px;
              cursor: pointer;
              line-height: 24px;
              text-align: center;
              & > img {
                opacity: 0;
              }
            }
            & > .check.active {
              transition: 0.5s all;
              width: 24px;
              height: 24px;
              background: #16bdc6;
              border-radius: 50%;
              position: absolute;
              box-shadow: inset 0px 1px 2px #2d9cdb;
              left: 11px;
              top: 13px;
              cursor: pointer;
              line-height: 24px;
              text-align: center;
              & > img {
                transition: 0.5s all;
                opacity: 1;
              }
            }

            & > img {
              position: absolute;
              top: 13px;
              right: 12px;
              cursor: pointer;
            }
            & > .image-box {
              width: 100%;
              max-height: 192px;
              border-radius: 2px;
              overflow: hidden;
              & > img {
                width: 320px;
              }
            }
            & > .title {
              margin-top: 2px;
              width: 100%;
              font-weight: 500;
              font-size: 16px;
              line-height: 125%;
            }
            & > .location {
              width: 100%;
              margin-top: 20px;
              font-weight: 300;
              font-size: 12px;
              line-height: 17px;
              color: #828282;
            }
            & > .types {
              padding-top: 8px;
              & > button {
                padding: 6px;
                height: 20px;
                background: #16bdc6;
                border-radius: 2px;
                font-size: 11px;
                font-weight: 500;
                color: #fff;
                line-height: 11px;
                margin-right: 8px;
              }

              & > span {
                font-weight: 300px;
                font-size: 11px;
                color: #828282;
              }
            }

            & > .price {
              & > p {
                font-weight: 300;
                font-size: 12px;
                line-height: 21px;
                & > span:first-child {
                  display: inline-block;
                  width: 54px;
                  color: #828282;
                  margin-right: 37px;
                }
              }
            }
          }
        }
      }
    }
  }
}
