@import "styles/_Common.scss";
div.passwordEmailCheck{
  width: 100%;
  min-height: 60vh;
  position: relative;
  & > div{
    position: absolute;
    width: 355px;
    height: 300px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin : auto;
    & >.textBody{
      & > h2{
        font-size: 32px;
        text-align: center;
        color : #000;
      }
      & > h4 {
        font-size: 14px;
        color : rgba(0,0,0,0.3);
        margin-top: 10px;
        text-align: center;
      }
    }

    & > .inputWrap{
      margin-top: 40px;
      & > div {
        & > input {
          width: 352px;
          height: 40px;
          border : 1px solid #e0e0e0;
          text-indent: 11px;
          margin-top: 10px;
          &.submitBtn{
            border-radius: 3px;
            background-color: #4f4f4f;
            color: #fff;
            text-indent: 0;
          }
        }
      }
    }

  }
}
@include media("<=medium", ">=small") {
  div.passwordEmailCheck{
  width: 100%;
  min-height: 60vh;
  position: relative;
  & > div{
    position: absolute;
    width: 90%;
    height: 300px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin : auto;
    & >.textBody{
      & > h2{
        font-size: 24px;
        text-align: center;
        color : #000;
      }
      & > h4 {
        font-size: 12px;
        color : rgba(0,0,0,0.3);
        margin-top: 10px;
        text-align: center;
      }
    }

    & > .inputWrap{
      margin-top: 40px;
      & > div {
        & > input {
          display: block;
          width: 90%;
          height: 40px;
          border : 1px solid #e0e0e0;
          text-indent: 11px;
          margin : 0 auto;
          margin-top: 10px;
          &.submitBtn{
            border-radius: 3px;
            background-color: #4f4f4f;
            color: #fff;
            text-indent: 0;
          }
        }
      }
    }

  }
}
}