@import "styles/_Common.scss";

.content {
  width: 1152px;
  margin: 0 auto;
  & > .popularity {
    width: 100%;
    & > .content {
      width: 100%;
      color: #828282;
      & > h2 {
        padding-top: 73px;
        padding-bottom: 10px;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        margin: 0 16px;
        color: #323232;
      }

      & > .popularity-items {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        & > div {
          min-height: 350px;
          & > div:hover {
            background: #f2f2f2;
          }
          & > div {
            position: relative;
            display: inline-block;
            & > a {
              display: block;
              width: 100%;
              height: 100%;
              & > div {
                position: relative;
                float: left;
                width: 256px;
                margin: 16px;
                & > .image-box {
                  width: 100%;
                  max-height: 192px;
                  border-radius: 2px;
                  overflow: hidden;
                  & > img {
                    width: 100%;
                    height: 100%;
                  }
                }
                & > .title {
                  // display: -webkit-box;
                  // -webkit-line-clamp: 2;
                  // -webkit-box-orient: vertical;
                  width: 100%;
                  height: 30px;
                  margin-top: 6px;
                  font-family: "Noto Sans KR";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 30px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                & > .location {
                  width: 100%;
                  margin-top: 7px;
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: 300;
                  font-size: 12px;
                  line-height: 21px;
                  // margin-bottom: 5px;
                  & > span {
                    color: #828282;
                  }
                }
                & > .types {
                  padding-top: 16px;
                  & > button {
                    padding: 6px;
                    height: 20px;
                    background: #16bdc6;
                    border-radius: 2px;
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    font-weight: 500;
                    color: #fff;
                    line-height: 9px;
                    margin-right: 14px;
                  }

                  & > span {
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    color: #828282;
                  }
                }
                & > .price {
                  & > p {
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 21px;
                    margin: 0;
                    & > span:first-child {
                      display: inline-block;
                      width: 54px;
                      color: #828282;
                      margin-right: 6px;
                    }
                    & > span:last-child {
                      font-family: Roboto;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 12px;
                      color: #323232;
                    }
                  }
                }
              }
            }
            & > .star-wrap {
              position: absolute;
              top: 15px;
              right: 15px;
              cursor: pointer;
              padding: 9px;
              width: 38px;
              height: 38px;

              & > img {
                width: 18px;
                // height: 18px;
              }
            }
          }
        }
      }
    }
  }

  & > .recently-viewed {
    & > .content {
      width: 100%;
      & > h2 {
        position: relative;
        padding-top: 73px;
        padding-bottom: 10px;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        margin: 0 16px;
        color: #bdbdbd;
        cursor: pointer;
        & > span {
          color: #bdbdbd;
          font-size: 20px;
          font-weight: 400;
          line-height: 29px;
        }
        & > a {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #828282;
          position: absolute;
          right: 0px;
          bottom: 0;
        }
      }

      & > .recently-viewed-items {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        & > div {
          & > div:nth-child(even) {
            & > div:nth-child(even) {
              margin-right: 0;
            }
          }

          & > div:hover {
            background: #f2f2f2;
          }
          & > div {
            position: relative;
            min-height: 350px;
            display: inline-block;
            & > a {
              display: block;
              & > div {
                margin: 16px;
                position: relative;
                float: left;
                width: 256px;
                & > .image-box {
                  width: 100%;
                  max-height: 192px;
                  min-height: 192px;
                  border-radius: 2px;
                  overflow: hidden;
                  & > img {
                    width: 100%;
                    height: 100%;
                    min-height: 192px;
                  }
                }
                & > .title {
                  // display: -webkit-box;
                  // -webkit-line-clamp: 2;
                  // -webkit-box-orient: vertical;
                  width: 100%;
                  height: 30px;
                  margin-top: 6px;
                  font-family: "Noto Sans KR";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 30px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                & > .location {
                  width: 100%;
                  margin-top: 7px;
                  font-weight: 300;
                  font-size: 12px;
                  line-height: 21px;
                  // margin-bottom: 5px;
                  & > span {
                    color: #828282;
                  }
                }
                & > .types {
                  padding-top: 16px;
                  & > button {
                    padding: 6px;
                    height: 20px;
                    background: #16bdc6;
                    border-radius: 2px;
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    font-weight: 500;
                    color: #fff;
                    line-height: 9px;
                    margin-right: 14px;
                  }

                  & > span {
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    color: #828282;
                  }
                }

                & > .price {
                  & > p {
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 21px;
                    margin-bottom: 0;
                    & > span:first-child {
                      display: inline-block;
                      width: 54px;
                      color: #828282;
                      margin-right: 6px;
                    }
                    & > span:last-child {
                      font-family: Roboto;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 12px;
                      color: #323232;
                    }
                  }
                }
              }
            }
            & > .star-wrap {
              position: absolute;
              top: 15px;
              right: 15px;
              cursor: pointer;
              padding: 9px;
              width: 38px;
              height: 38px;
              & > img {
                width: 18px;
                // height: 18px;
              }
            }
          }

          & > .more-house {
            position: relative;
            vertical-align: top;
            width: 288px;
            height: 367px;
            padding: 16px;
            cursor: pointer;

            & > .more-content {
              border: 1px solid #e0e0e0;
              border-radius: 2px;
              width: 100%;
              height: 100%;
              & > span {
                display: block;
                font-size: 16px;
                line-height: 23px;
                max-width: 144px;
                margin: 0 auto;
                margin-top: 100px;
                color: rgba(0, 0, 0, 0.3);
                text-align: center;
              }
              & > button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 45px;
                height: 45px;
                background-color: #e0e0e0;
                border-radius: 50%;
                margin: 19px auto 0;
              }
            }
          }
        }
      }
    }
  }

  & > .event-banner {
    width: 100%;
    position: relative;
    margin-top: 64px;
    // overflow-x: scroll;
    &::-webkit-scrollbar {
      height: 0px;
      opacity: 0;
    }
    img {
      cursor: pointer;
    }
    & > .content {
      width: 100%;
      display: block;
      & > div {
        float: left;
        width: calc(50% - 32px);
        margin: 0 16px;
        box-sizing: border-box;
        cursor: pointer;
        & > div {
          height: 156px;
          padding: 45px 0 0 56px;
          & > h3 {
            font-size: 18px;
            line-height: 24px;
            color: #000000;
            font-weight: 600;
            font-family: Roboto;
          }
          & > a {
            font-size: 12px;
            line-height: 24px;
            color: #000000;
            text-decoration: underline;
            margin-top: 16px;
          }
        }
      }
      & > div:nth-child(1) {
        background: linear-gradient(
          220.95deg,
          #e1b35a 27.23%,
          #daab52 45.45%,
          #d19b43 56.43%,
          #e2b65d 69.24%,
          #e2b65d 69.24%
        );
        & > div {
          background-size: cover;
          background-position: right;
          background: url(../../../assets/images/banner_01.png);
        }
      }
      & > div:nth-child(2) {
        background: #b2e6e6;
        & > div {
          background-size: cover;
          background-position: right;
          background: url(../../../assets/images/banner_02.png);
        }
      }
    }
    & > .content::after {
      content: "";
      display: block;
      clear: both;
    }
    & > .content[data-length="1"] {
      width: 50%;
      margin: 0 auto;
      & > div {
        width: calc((100% / 1) - 32px);
      }
    }
    & > .content[data-length="2"] {
      width: 100%;
      & > div {
        width: calc((100% / 2) - 32px);
      }
    }
    & > .content[data-length="3"] {
      width: calc(50% * 3);
      & > div {
        width: calc((100% / 3) - 32px);
      }
    }
    & > .content[data-length="4"] {
      width: calc(50% * 4);
      & > div {
        width: calc((100% / 4) - 32px);
      }
    }
    & > .content[data-length="5"] {
      width: calc(50% * 5);
      & > div {
        width: calc((100% / 5) - 32px);
      }
    }
    & > .content[data-length="6"] {
      width: calc(50% * 6);
      & > div {
        width: calc((100% / 6) - 32px);
      }
    }
    & > .content[data-length="7"] {
      width: calc(50% * 7);
      & > div {
        width: calc((100% / 7) - 32px);
      }
    }
    & > .content[data-length="8"] {
      width: calc(50% * 8);
      & > div {
        width: calc((100% / 8) - 32px);
      }
    }
    & > .content[data-length="9"] {
      width: calc(50% * 9);
      & > div {
        width: calc((100% / 9) - 32px);
      }
    }
    & > .content[data-length="10"] {
      width: calc(50% * 10);
      & > div {
        width: calc((100% / 10) - 32px);
      }
    }
  }
}

.main-banner {
  // 메인페이지 컨텐츠 최상단 검색 영역 풀width적용...
  width: 100%;
  position: relative;
  height: 480px;
  background-image: url("../../../assets/images/visaul_img01.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  & > .title {
    display: flex;
    justify-content: center;
    padding-top: 72px;
    & > h1 {
      display: inline-block;
      font-size: 64px;
      font-weight: bold;
      line-height: 64px;
      color: #27313b;
      text-align: left;
      margin-left: -350px;
      font-family: "Lato", sans-serif;
    }
  }
  & > .tab {
    padding-top: 40px;
    & > .tab-menu-wrap {
      width: 728px;
      margin: 0 auto;
      box-shadow: 4px 10px 16px rgba(0, 0, 0, 0.25);
      & > ul {
        display: table;
        height: 48px;
        width: 100%;
        & > li {
          width: calc(100% / 3);
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          height: 100%;
          border: 1px solid #e0e0e0;
          border-radius: 2px 2px 0px 0px;
          background: #fafafa;
          cursor: pointer;
          transition: 0.5s all;
          &:nth-child(1) ~ * {
            border-left: 0;
          }
        }

        & > .active {
          background: #16bdc6;
          border-color: #16bdc8;
          transition: 0.5s all;
          color: #fff;
          font-weight: 500;
        }
      }

      & > .form-active {
        position: relative;
        display: block;
        width: 100%;
        height: 200px;
        background-color: #fff;
        overflow-y: auto;
        z-index: 999;

        & > .help-content {
          width: 100%;
          padding: 10px;
          z-index: 999;

          & > p {
            display: inline-block;
            font-size: 13px;
            margin-bottom: 0;
            text-indent: 10px;
          }

          & > img {
             width: 16px;
            height: 16px;
            object-fit: contain;
            transform: translateY(3px);
          }
        }
      }

      & > .form-unactive {
        display: none;
      }

      & > form {
        width: 100%;
        position: relative;
        & > input {
          display: block;
          border: 1px solid #e0e0e0;
          border-top: 0;
          background-color: #fff;
          width: 100%;
          font-size: 16px;
          height: 55px;
          padding-left: 48px;
        }
        & > input::placeholder {
          color: #808080;
        }

        & > button {
          position: absolute;
          top: 50%;
          right: 9px;
          background: #4f4f4f;
          border-radius: 0 0 3px 3px;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          width: 66px;
          height: 40px;
          color: #fff;
          transform: translateY(-50%);
        }

        & > img {
          position: absolute;
          top: 50%;
          left: 18px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.inhabitation {
  // 메인 페이지 청년주거백과 인기 컨텐츠 나오는부분 ...
  width: 100%;
  overflow-x: hidden;
  position: relative;
  margin-top: 72px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: #e0e0e0;
  & > div {
    background: url("../../../assets/images/Visual_img02.png");
    background-repeat: no-repeat;
    background-position-x: 0px;
    background-position-y: 206px;
    width: 1138px;
    padding: 82px 0;
    & > .inhabi-text {
      float: left;
      width: 280px;
      height: 396px;
      padding-top: 16px;
      padding-left: 8px;
      & > h1 {
        font-size: 26px;
        font-weight: 600;
        line-height: 155%;
        color: #000000;
        letter-spacing: -0.4px;
      }
    }
    & > .inhabi-content {
      float: right;
      width: 848px;
      height: 396px;
      & > .inhabi-contents-left {
        float: left;
        height: 100%;
        width: 568px;
        & > a {
          display: block;
          cursor: pointer;
          & > div:hover {
            background: #f2f2f2;
          }
          & > div {
            margin: 0 8px;
            background-color: #fff;

            & > h3 {
              position: relative;
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 26px;
              margin-top: 23px;
              margin-left: 24px;
              & > span {
                font-weight: normal;
              }
            }
            & > h3::after {
              content: "";
              position: absolute;
              width: 17px;
              height: 3px;
              background-color: #000000;
              top: 66px;
              left: 3px;
            }
            & > p {
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.4);
              margin-top: 28px;
              margin-left: 24px;
            }
          }
        }

        & > a:nth-child(1) div {
          float: left;
          width: 360px;
          height: 190px;
          background: url(../../../assets/images/tower.png), #fff;
          background-repeat: no-repeat;
          background-position: right;
          margin-bottom: 16px;
        }
        & > a:nth-child(1):hover div {
          background: url(../../../assets/images/tower.png), #f2f2f2;
          background-repeat: no-repeat;
          background-position: right;
        }
        & > a:nth-child(2) div {
          float: left;
          width: 176px;
          height: 190px;
          margin-bottom: 16px;
        }
        & > a:nth-child(3) div {
          float: left;
          width: 168px;
          height: 190px;
        }
        & > a:nth-child(4) div {
          float: left;
          width: 368px;
          height: 190px;
          background: url(../../../assets/images/cat.png), #fff;
          background-repeat: no-repeat;
          background-position: right;
        }
        & > a:nth-child(4):hover div {
          background: url(../../../assets/images/cat.png), #f2f2f2;
          background-repeat: no-repeat;
          background-position: right;
        }
      }
      & > .inhabi-contents-right {
        float: right;
        width: 280px;
        height: 396px;
        & > a {
          display: block;
          & > div {
            height: 396px;
            width: 264px;
            margin: 0 8px;
            background: url(../../../assets/images/cat_2.png), #fff;
            background-position-x: 18px;
            background-position-y: 190px;
            background-repeat: no-repeat;
            & > h3 {
              position: relative;
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 26px;
              padding-top: 23px;
              margin-left: 24px;
              & > span {
                font-weight: normal;
              }
            }
            & > h3::after {
              content: "";
              position: absolute;
              width: 17px;
              height: 3px;
              background-color: #000000;
              top: 90px;
              left: 3px;
            }
            & > p {
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.4);
              margin-top: 28px;
              margin-left: 24px;
            }
          }
          & > div:hover {
            background: url(../../../assets/images/cat_2.png), #f2f2f2;
            background-position-x: 18px;
            background-position-y: 190px;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}

@include media("<=medium", ">=small") {
  .content {
    width: 100%;

    & > .popularity {
      & > .content {
        overflow: hidden;
        margin: 0 auto;
        & > h2 {
          margin-left: 16px;
          padding-left: 0px;
          max-width: 768px;
          min-width: 360px;
          padding-top: 32px;
          padding-bottom: 16px;
          font-weight: 500;
          font-size: 20px;
          line-height: 29px;
        }

        & > .popularity-items {
          overflow-x: auto;
          &::-webkit-scrollbar {
            height: 0px;
            background: rgba(0, 0, 0, 0);
          }
          &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0);
          }
          &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0);
          }
          & > div {
            width: 264px;
            display: flex;
            flex-wrap: nowrap;
            & > div:first-child {
              margin-left: 8px;
            }
            & > div:last-child {
              margin-left: 8px;
            }
            & > div {
              & > a {
                & > div {
                  position: relative;
                  float: left;
                  width: 264px;
                  height: 360px;
                  margin: 8px;
                  & > .image-box {
                    width: 100%;
                    max-height: 192px;
                    border-radius: 6px;
                    overflow: hidden;
                  }
                  & > .title {
                    margin-top: 2px;
                    width: 100%;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 30px;
                  }
                  & > .location {
                    width: 100%;
                    // margin-top: 8px;
                    // margin-bottom: 8px;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 17px;
                    & > span {
                      color: #828282;
                    }
                  }
                  & > .types {
                    padding-top: 16px;
                    & > button {
                      padding: 6px;
                      height: 20px;
                      background: #16bdc6;
                      border-radius: 2px;
                      font-size: 11px;
                      font-weight: 500;
                      color: #fff;
                      line-height: 8px;
                      margin-right: 8px;
                    }

                    & > span {
                      font-weight: 300px;
                      font-size: 11px;
                      color: #828282;
                    }
                  }

                  & > .price {
                    & > p {
                      font-weight: 300;
                      font-size: 12px;
                      line-height: 21px;
                      & > span:first-child {
                        display: inline-block;
                        width: 54px;
                        color: #828282;
                        margin-right: 37px;
                      }
                    }
                  }
                }
              }
              & > .star-wrap {
                position: absolute;
                top: 15px;
                right: 15px;
                cursor: pointer;
                padding: 4px;
                width: 26px;
                height: 26px;
                & > img {
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
        }
      }
    }

    & > .recently-viewed {
      & > .content {
        margin: 0 auto;
        & > h2 {
          padding-left: 0px;
          margin-left: 16px;
          width: calc(100% - 32px);
          position: relative;
          padding-top: 32px;
          padding-bottom: 16px;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;

          & > .seltype {
            color: #000000;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 16px !important;
            line-height: 23px;
          }

          & > span {
            color: #bdbdbd;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
          }
          & > a {
            font-size: 14px;
            line-height: 20px;
            color: #828282;
            position: absolute;
            right: 0px;
            bottom: 23px;
            line-height: 0;
          }
        }

        & > .recently-viewed-items {
          overflow-x: auto;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-wrap: nowrap;
          &::-webkit-scrollbar {
            height: 0px;
            background: rgba(0, 0, 0, 0);
          }
          &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0);
          }
          &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0);
          }
          & > div {
            display: flex;
            flex-wrap: nowrap;

            & > .more-house {
              border-radius: 2px;
              min-width: 246px;
              min-height: 350px;
              max-width: 256px;
              max-height: 350px;
              cursor: pointer;
              padding: 8px;

              & > .more-content {
                & > span {
                  display: block;
                  font-size: 16px;
                  line-height: 23px;
                  max-width: 144px;
                  margin: 0 auto;
                  margin-top: 100px;
                  color: rgba(0, 0, 0, 0.3);
                  text-align: center;
                }
                & > button {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 45px;
                  height: 45px;
                  background-color: #e0e0e0;
                  border-radius: 50%;
                  margin: 19px auto 0;
                }
              }
            }
            & > div:first-child {
              margin-left: 8px;
            }
            & > div:last-child {
              margin-right: 8px;
            }
            & > div {
              display: inline-block;
              position: relative;
              float: left;
              & > a {
                display: block;
                width: 100%;
                height: 100%;
                & > div {
                  margin: 8px;
                  width: 264px;
                  height: 360px;
                  overflow: hidden;
                  & > img {
                    position: absolute;
                    top: 8px;
                    right: 20px;
                    cursor: pointer;
                  }
                  & > .image-box {
                    width: 100%;
                    max-height: 192px;
                    border-radius: 6px;
                    overflow: hidden;
                  }
                  & > .title {
                    margin-top: 2px;
                    width: 100%;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 30px;
                  }
                  & > .location {
                    width: 100%;
                    // margin-top: 8px;
                    // margin-bottom: 8px;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 17px;
                    & > span {
                      color: #828282;
                    }
                  }
                  & > .types {
                    padding-top: 16px;
                    & > button {
                      padding: 6px;
                      height: 20px;
                      background: #16bdc6;
                      border-radius: 2px;
                      font-size: 11px;
                      font-weight: 500;
                      color: #fff;
                      line-height: 8px;
                      margin-right: 8px;
                    }

                    & > span {
                      font-weight: 300px;
                      font-size: 11px;
                      color: #828282;
                    }
                  }

                  & > .price {
                    & > p {
                      font-weight: 300;
                      font-size: 12px;
                      line-height: 21px;
                      & > span:first-child {
                        display: inline-block;
                        width: 54px;
                        color: #828282;
                        margin-right: 37px;
                      }
                    }
                  }
                }
              }
              & > .star-wrap {
                position: absolute;
                top: 15px;
                right: 15px;
                cursor: pointer;
                padding: 4px;
                width: 26px;
                height: 26px;
                & > img {
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
        }
      }
    }

    & > .event-banner {
      width: 100%;
      max-width: 400px;
      position: relative;
      margin-top: 16px;
      margin: 20px auto 0;
      display: block;
      // overflow-x: scroll;
      // overflow-y: hidden;
      // scroll-snap-type: x mandatory;
      transition: all 0.3s;
      &::-webkit-scrollbar {
        height: 0px;
        opacity: 0;
      }
      img {
        width: 100%;
        cursor: pointer;
      }
      & > .content {
        // width: 200%;
        // max-width: 800px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        & > div {
          display: block;
          // width: 50%;
          margin: 0;
          // scroll-snap-align: start;
          & > img {
            width: 100%;
            object-fit: cover;
          }
          & > div {
            padding: 19px 0 25px 30px;
            height: 98px;
            & > h3 {
              font-size: 14px;
              line-height: 18px;
              color: #000000;
              font-weight: bold;
              font-family: Roboto;
              margin-bottom: 6px;
            }
            & > a {
              font-size: 12px;
              line-height: 24px;
              color: #000000;
              text-decoration: underline;
              margin-top: 11px;
            }
          }
        }
        & > div:nth-child(1) {
          & > div {
            background-size: contain;
            background-position: right;
            background-repeat: no-repeat;
          }
        }

        & > div:nth-child(2) {
          & > div {
            background-size: contain;
            background-position: right;
            background-repeat: no-repeat;
          }
        }
      }
      & > .content[data-length="1"] {
        width: 100%;
        margin: 0 auto;
        & > div {
          width: calc(100% / 1);
        }
      }
      & > .content[data-length="2"] {
        width: calc(100% * 2);
        & > div {
          width: calc(100% / 2);
        }
      }
      & > .content[data-length="3"] {
        width: calc(100% * 3);
        & > div {
          width: calc(100% / 3);
        }
      }
      & > .content[data-length="4"] {
        width: calc(100% * 4);
        & > div {
          width: calc(100% / 4);
        }
      }
      & > .content[data-length="5"] {
        width: calc(100% * 5);
        & > div {
          width: calc(100% / 5);
        }
      }
      & > .content[data-length="6"] {
        width: calc(100% * 6);
        & > div {
          width: calc(100% / 6);
        }
      }
      & > .content[data-length="7"] {
        width: calc(100% * 7);
        & > div {
          width: calc(100% / 7);
        }
      }
      & > .content[data-length="8"] {
        width: calc(100% * 8);
        & > div {
          width: calc(100% / 8);
        }
      }
      & > .content[data-length="9"] {
        width: calc(100% * 9);
        & > div {
          width: calc(100% / 9);
        }
      }
      & > .content[data-length="10"] {
        width: calc(100% * 10);
        & > div {
          width: calc(100% / 10);
        }
      }
      & > .content:after {
        content: "";
        display: block;
        clear: both;
      }
    }
  }
  .inhabitation {
    max-width: 768px;
    min-width: 380px;
    overflow-x: hidden;
    position: relative;
    margin-top: 72px;
    min-height: 560px;
    background: url("../../../assets/images/Visual_img02.png"), #e0e0e0;
    background-repeat: no-repeat;
    background-position-x: 150px;
    background-position-y: 206px;
    display: none;
    justify-content: center;
    flex-wrap: wrap;
    & > .inhabi-text {
      float: left;
      max-width: 415px;
      min-width: 415px;
      & > h1 {
        font-size: 26px;
        font-weight: 600;
        line-height: 155%;
        color: #000000;
        padding-left: 150px;
        padding-top: 100px;
        letter-spacing: -0.4px;
      }
    }
    & > .inhabi-grid-content {
      float: left;
      min-height: 560px;
      min-width: 768px;
      max-width: 380px;
      max-height: 560px;
      display: flex;
      justify-content: center;

      & > .inhabi-grid {
        display: grid;
        grid-template-columns: 176px 176px 176px 176px 88px;
        grid-template-rows: 198px 198px;
        margin-top: 82px;
        margin-left: 33px;
        min-height: 396px;
        min-width: 865px;
        max-width: 865px;
        max-height: 396px;
        row-gap: 16px;
        column-gap: 16px;
        & > div {
          background-color: #fff;
          & > h3 {
            display: inline-block;
            font-size: 18px;
            line-height: 26px;
            font-weight: bold;
            margin-top: 28px;
            margin-left: 33px;
            & > span {
              position: relative;
              font-weight: normal;
              font-size: 18px;
            }
            & > span::after {
              content: "";
              position: absolute;
              width: 16px;
              border: 2px solid #000000;
              bottom: -30px;
              left: 0;
            }
          }

          & > p {
            margin-top: 42px;
            margin-left: 33px;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.4);
          }
        }
        & > div:nth-child(1) {
          grid-area: 1 / 1 / 2 / 3;
          background-image: url(../../../assets/images/tower.png);
          background-repeat: no-repeat;
          background-size: contain;
          background-position-x: right;
        }

        & > div:nth-child(2) {
          grid-area: 1 / 3 / 2 / 4;
        }

        & > div:nth-child(3) {
          grid-area: 1 / 4 / 3 / 6;
          background: url(../../../assets/images/cat_2.png), #fff;
          background-position-y: 194px;
          background-position-x: 7px;
          background-repeat: no-repeat;
        }

        & > div:nth-child(4) {
          grid-area: 2 / 1 / 3 / 2;
        }
        & > div:nth-child(5) {
          grid-area: 2 / 2 / 3 / 4;
          background: url(../../../assets/images/cat.png), #fff;
          background-position: right center;
          background-repeat: no-repeat;
          background-size: 192px 198px;
        }
      }
    }
  }
  .main-banner {
    width: 100%;
    position: relative;
    height: 285px;
    background-image: url("../../../assets/images/visaul_img01.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 260% 100%;
    & > .title {
      position: relative;
      width: 96%;
      max-width: 400px;
      margin: 0 auto;
      text-align: left;
      padding-top: 40px;
      & > h1 {
        display: inline-block;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        color: #27313b;
        text-align: left;
        line-height: 37px;
        width: 100%;
        margin-left: 0%;
        margin-bottom: 24px;
      }
    }
    & > .tab {
      padding-top: unset;
      max-width: 400px;
      width: 96%;
      margin: 0 auto;

      & > .tab-menu-wrap {
        width: 100%;
        box-shadow: 4px 10px 16px rgba(0, 0, 0, 0.25);
        & > ul {
          display: block;
          height: 40px;
          max-width: 768px;
          width: 100%;
          & > li {
            width: calc(100% / 3);
            display: inline-block;
            line-height: 40px;
            // vertical-align: middle;
            text-align: center;
            height: 100%;
            border: 1px solid #e0e0e0;
            border-radius: 2px 0px 0px 0px;
            background: #fafafa;
            cursor: pointer;
            transition: 0.5s all;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: lighter;
            font-size: 13px;
            color: #000000;
          }

          & > .active {
            background: #16bdc6;
            border-color: #16bdc8;
            transition: 0.5s all;
            color: #fff;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            font-weight: bold;
          }
        }
        & > form {
          width: 100%;
          overflow: hidden;
          position: relative;
          & > input {
            display: block;
            border: 1px solid #e0e0e0;
            background-color: #fff;
            width: 100%;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            height: 46px;
            padding-left: 48px;
          }
          & > input::placeholder {
            color: #bdbdbd;
          }

          & > button {
            position: absolute;
            top: 50%;
            right: 9px;
            background: #4f4f4f;
            border-radius: 3px;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            width: 46px;
            height: 32px;
            color: #fff;
            transform: translateY(-50%);
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
          }

          & > img {
            position: absolute;
            top: 50%;
            left: 18px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

.seltype {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  color: #323232 !important;
}
