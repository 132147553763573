@import "styles/_Common.scss";
.mypage-user-data {
  padding-bottom: 50px;
  & > h1 {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    margin-top: 48px;
    margin-bottom: 25px;
    text-align: center;
  }
  & > span {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    display: block;
  }
}

.mypage-category {
  width: 100%;
  padding-top: 15px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  & > .main-category {
    & > ul {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      & > li {
        display: inline-block;
        max-width: 224px;
        width: 100%;
        margin-right: 24px;
        & > a {
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          color: rgba(0, 0, 0, 0.3);
        }
      }
      & > li.primary-blue {
        padding-bottom: 13px;
        & > a {
          color: #16bdc6;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
        }
        border-bottom: 3px solid #16bdc6;
      }
    }
  }
  & > .sub-category {
    margin-top: 30px;
    & > ul {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      & > li {
        display: inline-block;
        width: 240px;
        margin-right: 24px;

        & > a {
          width: 100%;
          display: block;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          padding-bottom: 16px;
        }
        & > .primary_blue {
          border-bottom: 3px solid #16bdc6;
          color: #16bdc6;
        }
      }
    }
  }
}

.Inquiry {
  margin: 0 16px 197px 16px;
  & > div {
    max-width: 1120px;
    min-width: 1024px;
    width: 100%;
    margin: 0 auto;
    & > button {
      margin-top: 40px;
      margin-bottom: 20px;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 2px;
      background: #ffffff;
      padding: 11px 24px 11px 24px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      height: 40px;
    }
    & > table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      & > thead {
        & > tr {
          & > th {
            padding: 11.5px 0;
            text-align: left;
            & > input.chk-box {
              position: absolute;
              display: none;
            }
            & > input.chk-box + label em {
              position: relative;
              display: inline-block;
              transform: translateY(50%);
              width: 24px;
              height: 24px;
              top: -4px;
              background: rgba(255, 255, 255, 0.3);
              border: 1px solid #e2e2e2;
              border-radius: 3px;
            }
            & > input.chk-box + label {
              line-height: 45px;
            }
            & > input.chk-box:checked + label em {
              background: #16bdc6;
              & > img {
                display: block;
                top: 6px;
                z-index: 11111;
              }
            }
            & > label > em > img {
              position: relative;
              display: none;
              top: -15px;
              left: 5px;
            }
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            color: rgba(0, 0, 0, 0.3);
          }
          & > th:last-child {
            text-align: center;
          }
        }
      }
      & > tbody {
        & > tr {
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
          & > td {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            & > input.chk-box {
              position: absolute;
              display: none;
            }
            & > input.chk-box + label em {
              position: relative;
              display: inline-block;
              transform: translateY(50%);
              width: 24px;
              height: 24px;
              top: -8px;
              background: rgba(255, 255, 255, 0.3);
              border: 1px solid #e2e2e2;
              border-radius: 3px;
            }
            & > input.chk-box + label {
              line-height: 45px;
            }
            & > input.chk-box:checked + label em {
              background: #16bdc6;
              & > img {
                display: block;
                top: 6px;
                z-index: 11111;
              }
            }
            & > label > em > img {
              position: relative;
              display: none;
              top: -15px;
              left: 5px;
            }
            & > a {
              display: block;
              width: 100%;
              height: 100%;
              & > p {
                display: inline-block;
                width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0;
              }
              & > span {
                display: inline-block;
                width: 100%;
              }
            }
          }
          & > td.content {
            padding: 16px 0;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;

            & > img {
              width: 18px;
              height: 18px;
            }

            & > a {
              display: inline-block;
              max-width: 500px;
              width: 100%;
              height: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: rgba(0, 0, 0, 0.65);
            }
          }
          & > td:last-child {
            text-align: center;
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }
    }
  }
}
@include media("<=medium", ">=small") {
  .mypage-user-data {
    padding-bottom: 50px;
    & > h1 {
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      margin-top: 48px;
      margin-bottom: 25px;
      text-align: center;
    }
    & > span {
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.65);
      text-align: center;
      display: block;
    }
  }

  .mypage-category {
    width: 100%;
    padding-top: 15px;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    & > .main-category {
      & > ul {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        & > li {
          display: inline-block;
          max-width: 224px;
          width: 100%;
          margin-right: 24px;
          & > a {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            color: rgba(0, 0, 0, 0.3);
          }
        }
        & > li.primary-blue {
          padding-bottom: 13px;
          & > a {
            color: #16bdc6;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
          }
          border-bottom: 3px solid #16bdc6;
        }
      }
    }
    & > .sub-category {
      margin-top: 30px;
      & > ul {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        & > li {
          display: inline-block;
          width: 240px;
          margin-right: 24px;

          & > a {
            width: 100%;
            display: block;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            padding-bottom: 16px;
          }
          & > .primary_blue {
            border-bottom: 3px solid #16bdc6;
            color: #16bdc6;
          }
        }
      }
    }
  }

  .Inquiry {
    margin-bottom: 197px;
    display: none;
    & > div {
      max-width: 768px;
      min-width: 320px;
      width: 100%;
      margin: 0 20px;
      & > button {
        margin-top: 24px;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 2px;
        background: #ffffff;
        padding: 14px 24px 13px 23px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
      }
      & > table {
        margin-top: 22px;
        border-collapse: collapse;
        border-spacing: 0;
        & > thead {
          & > tr {
            & > th {
              padding: 11.5px 0;
              text-align: left;
              & > label {
                display: block;
                border: 2px solid #e0e0e0;
                width: 24px;
                height: 24px;
                border-radius: 2px;
                background-color: #fff;
                & > input {
                  opacity: 0;
                }
              }
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              color: rgba(0, 0, 0, 0.3);
            }
          }
        }
        & > tbody {
          & > tr {
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
            & > td {
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              & > label {
                display: block;
                border: 2px solid #e0e0e0;
                width: 24px;
                height: 24px;
                border-radius: 2px;
                background-color: #fff;
                & > input {
                  opacity: 0;
                }
              }
            }
            & > td.content {
              padding: 16px 0;
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              color: rgba(0, 0, 0, 0.65);
            }
          }
        }
      }
    }
  }
}
