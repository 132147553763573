@import "styles/_Common.scss";

.mypage-user-data {
  padding-bottom: 50px;
  & > h1 {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    margin-top: 48px;
    margin-bottom: 25px;
    text-align: center;
  }
  & > span {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    display: block;
  }
}

.mypage-category {
  width: 100%;
  padding-top: 15px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  & > .main-category {
    & > ul {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      & > li {
        display: inline-block;
        max-width: 224px;
        width: 100%;
        margin-right: 24px;
        & > a {
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          color: rgba(0, 0, 0, 0.3);
        }
      }
      & > li.primary-blue {
        padding-bottom: 13px;
        & > a {
          color: #16bdc6;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
        }
        border-bottom: 3px solid #16bdc6;
      }
    }
  }
  & > .sub-category {
    margin-top: 30px;
    & > ul {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      & > li {
        display: inline-block;
        width: 240px;
        margin-right: 24px;

        & > a {
          width: 100%;
          display: block;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          padding-bottom: 16px;
        }
        & > .primary_blue {
          border-bottom: 3px solid #16bdc6;
          color: #16bdc6;
        }
      }
    }
  }
}
.popularity {
  min-height: 700px;
  margin-bottom: 24px;
}
.story {
  width: 1124px;
  margin: 0 auto;

  & > h2 {
    position: relative;
    padding-top: 40px;
    padding-bottom: 8px;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 16px;
    & > input[type="checkbox"] {
      display: none;
    }
    & > input[type="checkbox"] + label {
      & > span {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0px 8px -2px 0px;
        border: 1px solid #bcbcbc;
        border-radius: 50%;
        cursor: pointer;
      }
    }
    & > input[type="checkbox"]:checked + label {
      & > span {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0px 8px -2px 0px;
        border: 1px solid #bcbcbc;
        border-radius: 50%;
        background: #16bdc6;
        cursor: pointer;
      }
    }
    &>div{
      display: inline-block;
    }
  }

  & > button {
    width: 88px;
    height: 40px;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    background: #fff;
    float: right;
    vertical-align: middle;
    display: block;
    margin-top: 40px;
    margin-bottom: 8px;
    margin-right: 32px;
    margin-left: 20px;
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
    max-width: 1120px;
    min-width: 1024px;
    margin: 0 auto;
    
    & > .story-card {
      width: 245px;
      margin: 16px 16px 40px 16px;
      cursor: pointer;
      position: relative;
      & > .contentWrap{
        & > .card-image {
          margin-bottom: 8px;
          & > img {
            width: 100%;
            height: auto;
          }
        }
        & > .card-title {
          width: 100%;
          height: 23px;
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          margin-bottom: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
  
        & > .card-footer {
          width: 100%;
          & > span:first-child {
            float: left;
            line-height: 20px;
          }
          & > span:nth-child(2) {
            text-align: center;
            margin-left: 8px;
          }
          & > span:nth-child(3) {
            margin-left: 8px;
            text-align: center;
            line-height: 21px;
          }
          & > span {
            color: #828282;
            float: right;
            font-weight: 300;
            font-size: 12px;
            vertical-align: middle;
            & > img {
              margin-right: 2px;
            }
          }
        }

      }
      & > h3 {
      position: absolute;
      top: -23px;
      left: -20px;
      & > input.chk-box {
        position: absolute;
        display: none;
      }
      & > input.chk-box + label em {
        position: relative;
        display: inline-block;
        transform: translateY(50%);
        width: 24px;
        height: 24px;
        margin: 18px 27px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 50%;
      }
      & > input.chk-box + label {
        line-height: 45px;
      }
      & > input.chk-box:checked + label em {
        background: #16bdc6;
        & > img {
          display: block;
          top: 6px;
          z-index: 11111;
        }
      }
      & > label > em > img {
        position: relative;
        display: none;
        top: -15px;
        left: 5px;
      }
    }
    }
  }
}

@include media("<=medium", ">=small") {
  .mypage-user-data {
    padding-bottom: 50px;
    & > h1 {
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      margin-top: 48px;
      margin-bottom: 25px;
      text-align: center;
    }
    & > span {
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.65);
      text-align: center;
      display: block;
    }
  }

  .mypage-category {
    width: 100%;
    padding-top: 15px;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    & > .main-category {
      & > ul {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        & > li {
          display: inline-block;
          max-width: 224px;
          width: 100%;
          margin-right: 24px;
          & > a {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            color: rgba(0, 0, 0, 0.3);
          }
        }
        & > li.primary-blue {
          padding-bottom: 13px;
          & > a {
            color: #16bdc6;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
          }
          border-bottom: 3px solid #16bdc6;
        }
      }
    }
    & > .sub-category {
      margin-top: 30px;
      & > ul {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        & > li {
          display: inline-block;
          width: 240px;
          margin-right: 24px;

          & > a {
            width: 100%;
            display: block;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            padding-bottom: 16px;
          }
          & > .primary_blue {
            border-bottom: 3px solid #16bdc6;
            color: #16bdc6;
          }
        }
      }
    }
  }
  .story {
    margin-top: 32px;
    & > div {
      display: flex;
      flex-wrap: wrap;
      max-width: 1120px;
      min-width: 1024px;
      margin: 0 auto;

      & > .story-card:last-child {
        margin-right: 0;
      }
      & > .story-card {
        max-width: 256px;
        width: 100%;
        margin-right: 32px;
        & > .card-image {
          margin-bottom: 8px;
          & > div {
            width: 100%;
            height: 100%;
            & > img {
              width: 100%;
              height: auto;
            }
          }
        }
        & > .card-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          margin-bottom: 10px;
        }

        & > .card-footer {
          width: 100%;
          & > span:first-child {
            float: left;
          }
          & > span {
            color: #828282;
            float: right;
            font-weight: 300;
            font-size: 12px;
            vertical-align: middle;
            line-height: 16px;
            & > img {
              margin-right: 2px;
            }
          }
          & > span:first-child {
            margin-right: 90px;
          }
          & > span:last-child {
            margin-left: 14px;
          }
        }
      }
    }
  }
}

@include media("<=medium", ">=small") {
  .mypage-user-data {
    padding-bottom: 50px;
    & > h1 {
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      margin-top: 48px;
      margin-bottom: 25px;
      text-align: center;
    }
    & > span {
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.65);
      text-align: center;
      display: block;
    }
  }

  .mypage-category {
    width: 100%;
    padding-top: 15px;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    & > .main-category {
      & > ul {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        & > li {
          display: inline-block;
          max-width: 224px;
          width: 100%;
          margin-right: 24px;
          & > a {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            color: rgba(0, 0, 0, 0.3);
          }
        }
        & > li.primary-blue {
          padding-bottom: 13px;
          & > a {
            color: #16bdc6;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
          }
          border-bottom: 3px solid #16bdc6;
        }
      }
    }
    & > .sub-category {
      margin-top: 30px;
      & > ul {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        & > li {
          display: inline-block;
          width: 240px;
          margin-right: 24px;

          & > a {
            width: 100%;
            display: block;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            padding-bottom: 16px;
          }
          & > .primary_blue {
            border-bottom: 3px solid #16bdc6;
            color: #16bdc6;
          }
        }
      }
    }
  }
  .story {
    width: 95%;
    margin-top: 32px;
    & > div {
      display: flex;
      flex-wrap: wrap;
      max-width: 1120px;
      min-width: 1024px;
      margin: 0 auto;

      & > .story-card:last-child {
        margin-right: 0;
      }
      & > .story-card {
        max-width: 100%;
        width: 100%;
        margin-right: 32px;
        & > .card-image {
          margin-bottom: 8px;
          & > div {
            width: 100%;
            height: 100%;
            & > img {
              width: 100%;
              height: auto;
            }
          }
        }
        & > .card-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          margin-bottom: 10px;
        }

        & > .card-footer {
          width: 100%;
          & > span:first-child {
            float: left;
          }
          & > span:nth-child(2) {
            margin-left: 8px;
          }
          & > span {
            color: #828282;
            float: right;
            font-weight: 300;
            font-size: 12px;
            vertical-align: middle;
            line-height: 16px;
            & > img {
              margin-right: 2px;
            }
          }
          & > span:first-child {
            margin-right: 90px;
          }
          & > span:last-child {
            margin-left: 14px;
          }
        }
      }
    }
  }

  .story:last-child {
    margin-bottom: 278px;
  }

  @include media("<=medium", ">=small") {
    .mypage-user-data {
      padding-bottom: 50px;
      & > h1 {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        margin-top: 48px;
        margin-bottom: 25px;
        text-align: center;
      }
      & > span {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
        display: block;
      }
    }

    .mypage-category {
      width: 100%;
      padding-top: 15px;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      & > .main-category {
        & > ul {
          width: 100%;
          text-align: center;
          margin: 0 auto;
          & > li {
            display: inline-block;
            max-width: 224px;
            width: 100%;
            margin-right: 24px;
            & > a {
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              color: rgba(0, 0, 0, 0.3);
            }
          }
          & > li.primary-blue {
            padding-bottom: 13px;
            & > a {
              color: #16bdc6;
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
            }
            border-bottom: 3px solid #16bdc6;
          }
        }
      }
      & > .sub-category {
        margin-top: 30px;
        & > ul {
          width: 100%;
          text-align: center;
          margin: 0 auto;
          & > li {
            display: inline-block;
            width: 240px;
            margin-right: 24px;

            & > a {
              width: 100%;
              display: block;
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              padding-bottom: 16px;
            }
            & > .primary_blue {
              border-bottom: 3px solid #16bdc6;
              color: #16bdc6;
            }
          }
        }
      }
    }
    .story {
      margin-top: 0px;
      width: 90%;
      max-width: 430px;
      margin: 0 auto;
      cursor: pointer;

      & > div {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        min-width: 100%;
        margin: 0 0px;
        justify-content: center;

        & > .story-card:nth-child(even) {
          margin-right: 16px;
        }
        & > .story-card {
          max-width: 100%;
          width: 100%;
          margin: 0;
          margin-top: 20px;
          & > .card-image {
            margin-bottom: 8px;
            & > div {
              width: 100%;
              height: 100%;
              & > img {
                width: 100%;
                height: auto;
              }
            }
          }
          & > .card-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            margin-bottom: 10px;
            width: 100%;
          }

          & > .card-footer {
            width: 100%;
            & > span:first-child {
              float: left;
            }
            & > span:nth-child(2) {
              margin-left: 16px;
            }
            & > span {
              color: #828282;
              float: right;
              font-weight: 300;
              font-size: 12px;
              vertical-align: middle;
              display: block;
              line-height: 23px;
              & > img {
                margin-right: 2px;
                margin-bottom: -2px;
              }
            }
            & > span:first-child {
              margin-right: 90px;
            }
            & > span:last-child {
              margin-left: 14px;
            }
          }
        }
      }
    }

    .story:last-child {
      margin-bottom: 278px;
    }
  }
}
