@import "~include-media/dist/include-media";
* {
  -webkit-tap-highlight-color: transparent;
}

$breakpoints: (
  small: 280px,
  medium: 1080px,
  large: 1440px
);
input, button {
  -webkit-appearance: none !important;
}
// $primary: (
//     color: white,
//     background: #0b245d
// );
// $primary-invert: (
//     color: #0b245d,
//     background: white
// );

// $button: (
//     backgorund: #f3f3f3
// );
