@import "styles/_Common.scss";

.dictionary-contents {
  width: 1152px;
  margin: 80px auto 120px;
  & > .contents-list {
    width: 100%;
    & > .houselife-report {
      position: relative;
      & > div {
        display: flex;
        flex-wrap: wrap;
        & > .third-cols:hover {
          background: #f2f2f2;
        }
        & > .third-cols {
          text-align: center;
          width: 352px;
          margin: 0px 16px 20px 16px;
          padding-bottom: 8px;
          cursor: pointer;

          & > h3 {
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 12px;
            height: 30px;
            line-height: 30px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
          }
          & > .image-cover {
            width: 352px;
            height: 264px;
            overflow: hidden;
            border-radius: 2px;
            margin-bottom: 24px;
            & > img {
              width: 100%;
              height: 100%;
            }
          }
          & > .publisher {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            margin-bottom: 4px;
            color: #828282;
            & > .profile {
              width: 22px;
              height: 22px;
              overflow: hidden;
              border: 50%;
              display: inline-block;
              margin-right: 6px;
              vertical-align: middle;
              & > img {
                text-align: center;
              }
            }
          }

          & > .card-footer {
            width: 100%;
            text-align: center;
            & > span {
              font-family: IBM Plex Sans;
              font-style: normal;
              font-weight: 300;
              font-size: 12px;
              line-height: 16px;
              vertical-align: middle;
              margin-right: 9px;
              color: #828282;
              & > img {
                margin-right: 2px;
                vertical-align: middle;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}

@include media("<=medium", ">=small") {
  .dictionary-wrap {
    display: flex;
    justify-content: center;
  }
  .dictionary-contents {
    width: 100%;
    display: flex;
    justify-content: center;
    & > .contents-list {
      max-width: 430px;
      width: 96%;
      margin: 0 auto;
      & > .houselife-report {
        position: relative;
        & > div {
          display: flex;
          flex-wrap: wrap;
          & > .third-cols:hover {
            background: #f2f2f2;
          }
          & > .third-cols {
            text-align: center;
            width: 100%;

            & > h3 {
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              margin-bottom: 8px;
            }
            & > .image-cover {
              width: 100%;
              height: auto;
              overflow: hidden;
              border-radius: 2px;
              margin-bottom: 16px;
              & > img {
                width: 100%;
              }
            }
            & > .publisher {
              margin-right: 15px;
              display: inline-block;
              font-family: IBM Plex Sans;
              font-style: normal;
              font-weight: 300;
              font-size: 12px;
              margin-bottom: 8px;
              color: #828282;
              & > .profile {
                width: 22px;
                height: 22px;
                overflow: hidden;
                border: 50%;
                display: inline-block;
                margin-right: 6px;
                vertical-align: middle;
                & > img {
                  text-align: center;
                }
              }
            }

            & > .card-footer {
              width: unset;
              text-align: center;
              display: inline-block;

              & > span {
                font-family: IBM Plex Sans;
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                vertical-align: middle;
                margin-right: 9px;
                color: #828282;
                & > img {
                  margin-right: 2px;
                  vertical-align: middle;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
