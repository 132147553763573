@import "styles/_Common.scss";

.Login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    background-color: #ffffff;
    
    & > .login-wrap {
        width: 352px;
        margin: 0 auto;
        text-align: center;
        background-color: #ffffff;
        & > .close {
        width: 24px;
        height: 24px;
        background-image: url(../../../assets/images/close.png);
        background-repeat: no-repeat;
        position: absolute;
        top: 26px;
        right: 28px;
    }
        & > h1 {
            font-size: 32px;
            margin-top: 42px;
            margin-bottom: 8px;
        }
        & > span {
            display: block;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.3);
            margin-bottom: 24px;
        }
        & > form:nth-child(4) {
            & > label {
                position: relative;
                & > input {
                    width: 352px;
                    border: 1px solid #e0e0e0;
                    margin: 8px 0;
                    height: 40px;
                    font-size: 13px;
                    padding-left: 8px;
                }
                & > img {
                    position: absolute;
                    top: 50%;
                    left: 88px;
                    transform: translateY(-50%);
                    width: 30px;
                    height: 30px;
                    object-fit: contain;
                }
            }
        }

        & > form:nth-child(5) {
            margin-top: 24px;

            & > div {
                margin-top: 16px;
                margin-bottom: 62px;
                & > label {
                    margin-right: 138px;
                }
                & > div::after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    border: 1px solid #e0e0e0;
                    transform: rotate(90deg) translate(-50%, -50%);
                    top: 70%;
                    left: 55%;
                }
                & > label,
                div {
                    position: relative;
                    display: inline-block;
                    font-weight: 300;
                    font-size: 12px;
                    & > input[type="checkbox"] {
                        display: inline-block;
                        vertical-align: middle;
                        width: 16px;
                        height: 16px;
                        border: 1px solid #e0e0e0;
                        margin-right: 10px;
                        background-color: #ffffff;
                    }
                    & > a:first-child {
                        margin-right: 28px;
                    }
                    & > a:last-child {
                        color: #16bdc6;
                    }
                }
            }

            & > input[type="submit"] {
                background-color: #4f4f4f;
                border-radius: 3px;
                text-align: center;
                line-height: 48px;
                height: 48px;
                width: 352px;
                font-weight: 500;
                font-size: 15px;
                margin-top: 10px;
                color: #ffffff;
            }
            & > label {
                position: relative;
                & > input {
                    width: 352px;
                    border: 1px solid #e0e0e0;
                    margin: 8px 0;
                    height: 40px;
                    font-size: 13px;
                    padding-left: 8px;
                }
                & > img {
                    position: absolute;
                    top: 50%;
                    left: 88px;
                    transform: translateY(-50%);
                    width: 30px;
                    height: 30px;
                    object-fit: contain;
                }
            }
        }
    }
}

@include media("<=medium", ">=small") {
    .Login {
        position: fixed;
        top: 0;
        left: 0;
        transform: translate( 0, 0);
        width: 100%;
        background-color: #ffffff;
        height: 100%;
        & > .login-wrap {
            max-width: 100%x;
            min-width: 100%;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate( -50%, -50%);
            height: 100%;
            overflow-y: auto;
            margin: 0;
            text-align: center;
            background-color: #ffffff;
            &::-webkit-scrollbar {
                width: 8px;
                background: rgba(0, 0, 0, 0);
            }
            
            & > .close {
                width: 24px;
                height: 24px;
                background-image: url(../../../assets/images/close.png);
                background-repeat: no-repeat;
                position: absolute;
                top: 26px;
                right: 28px;
                z-index: 111111;
            }
            & > h1 {
                font-size: 32px;
                margin-top: 42px;
                margin-bottom: 8px;
                width: 100%;
            }
            & > span {
                display: block;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.3);
                margin-bottom: 24px;
            } 
            & > form {
                width: 100%;
                max-width: 380px;
                padding: 0 32px;
                margin: 0 auto;
            }
            & > form:nth-child(4) {
                & > label {
                    position: relative;
                    & > input {
                        width: 100%;
                        border: 1px solid #e0e0e0;
                        margin: 8px 0;
                        height: 40px;
                        font-size: 13px;
                        padding-left: 8px;
                    }
                    & > img {
                        position: absolute;
                        top: 50%;
                        left: 15%;
                        transform: translateY(-50%);
                    }
                }
            }

            & > form:nth-child(5) {
                margin-top: 24px;

                & > div {
                    margin-top: 16px;
                    margin-bottom: 62px;
                    & > label {
                        margin: 0;
                        text-align: left;
                        width: 40%;
                    }
                    & > div {
                      width: 60%;
                        text-align: right;
                    }
                    & > div::after {
                        content: "";
                        position: absolute;
                        width: 10px;
                        border: 1px solid #e0e0e0;
                        transform: rotate(90deg) translate(-50%, -50%);
                        top: 70%;
                        left: 55%;
                    }
                    & > label,
                    div {
                        position: relative;
                        display: inline-block;
                        font-weight: 300;
                        font-size: 12px;
                        & > input[type="checkbox"] {
                            display: inline-block;
                            vertical-align: middle;
                            width: 16px;
                            height: 16px;
                            border: 1px solid #e0e0e0;
                            margin-right: 10px;
                            background-color: #ffffff;
                        }
                        & > a:first-child {
                            margin-right: 28px;
                        }
                        & > a:last-child {
                            color: #16bdc6;
                        }
                    }
                }

                & > input[type="submit"] {
                    background-color: #4f4f4f;
                    border-radius: 3px;
                    text-align: center;
                    line-height: 48px;
                    height: 48px;
                    width: 100%;
                    font-weight: 500;
                    font-size: 15px;
                    margin-top: 10px;
                    color: #ffffff;
                }
                & > label {
                    position: relative;
                    & > input {
                        width: 100%;
                        border: 1px solid #e0e0e0;
                        margin: 8px 0;
                        height: 40px;
                        font-size: 13px;
                        padding-left: 8px;
                    }
                    & > img {
                        position: absolute;
                        top: 50%;
                        left: 88px;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}
